import React, { Component } from 'react';
import '../../App.css';
import '../../Style.css';
import '../../script.js';
import Headermain from '../Headermain';
//import { Link, useHistory, useParams } from 'react-router-dom';
import Footer from '../Footer';
import axios from 'axios';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
        name: '',
        email:'',
        message:''
      };
  }


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const data = {
      name: this.state.name,
      email:this.state.email,
      message:this.state.message,
    };
    axios
    .post('/api/pages/contact',data)
    .then(res => {
      this.setState({
        name: '',
        email:'',
        message:''
      });
      //alert(res.data.msg);
      if(res.status===200)
      {
      console.log(res.data.id);
      alert(res.data.msg);
      }
      else
      {
        alert(res.data.msg);
      }
    })
    .catch(err => {
      alert(err.data.msg);
      console.log("Error in CreateBook!");
    })
};
  

  render() {
    

    return (
      <>
      <Headermain/>
<div className="container">
    <h2 className='contact_head'>Contact</h2><br/>
<div className ="row">
<div className="col-md-2"></div>
<div className ="col-md-2"><h3>Address</h3><h5>Lorem Ipsum</h5><h6>1234, abc street</h6><p>Lorem ipsum, lorem ipsum</p></div>
<div className ="col-md-2"></div>
<div className ="col-md-4">
<form noValidate onSubmit={this.onSubmit} >
<div className="form-group">
<input className="form-control" type="text" name="name" placeholder="Name" value={this.state.name} onChange={this.onChange}/>
</div><br/>
<div className="form-group">
<input className="form-control" type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.onChange}/>
</div><br/>
<div className="form-group">
<textarea className="form-control" placeholder="Message..."></textarea>
</div>
<input name="submit" value="Submit" type="submit" className="button-nav"/>
</form>
</div>
</div>
</div>      

<Footer/>

      </>
        
        
    );
  }
}

export default Contact;