import React from 'react';
import '../modal.css';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      val:'',
      p_id:''
    };
  }

  componentDidMount() {
    // Show the modal on component mount
    this.setState({ isOpen: true });
    const token = localStorage.getItem("token");
    const ru = jwtDecode(token);
    this.setState({p_id:ru.id});
  }

  handleYesClick = () => {
    // Handle the "Yes" button click
    this.setState({ isOpen: false });
    // You can add your custom logic here for the "Yes" choice
    this.setState({val:'yes'});
    //alert('You Clicked Yes');
    var data = {id:this.state.p_id,sms_updates:'yes'};
    axios
      .post('/api/users/get-sms-updates/', data)
      .then(res => {
        this.setState({
          reqqs1: res.data
         })
         console.log(res.data);
      })
      .catch(err =>{
        console.log('Error from ShowBookList');
      })
  };

  handleNoClick = () => {
    // Handle the "No" button click
    this.setState({ isOpen: false });
    // You can add your custom logic here for the "No" choice
    this.setState({val:'no'});
    //alert('You Clicked No');
    var data = {id:this.state.p_id,sms_updates:'no'};
    axios
      .post('/api/users/get-sms-updates/', data)
      .then(res => {
        this.setState({
          reqqs1: res.data
         })
         console.log(res.data);
      })
      .catch(err =>{
        console.log('Error from ShowBookList');
      })
  };

  render() {
    const { isOpen } = this.state;

    if (!isOpen) return null;

    return (
      <div className="modal">
        <div className="modal-content">
          <p>Do you wish to receive SMS updates?</p>
          <button onClick={this.handleYesClick}>Yes</button>
          <button onClick={this.handleNoClick}>No</button>
        </div>
      </div>
    );
  }
}

export default Modal;