import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from './Header';
import jwtDecode from 'jwt-decode';
import Stripe from 'react-stripe-checkout';
import Headertop from './Headeradmintop';
import Headeruser from './Headeruser';

class SubmitReview extends Component {
  
  constructor() {
    
    //console.log(id);
    
    super();
    this.state = {
      review:'',
      stars:''
    };
    
  }

  componentDidMount() {
    
    // console.log("Print id: " + this.props.match.params.id);
    if(localStorage.getItem("token"))
    {
    const token = localStorage.getItem("token");
    if(!token)
    {
      window.location.href = "/login";
    }
    const u = jwtDecode(token);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/user-logout";
    }
    const uservalue = axios
      .get('/api/users/get-user/'+u.id)
      .then(res => {
        this.setState({
          user: res.data
         })
        console.log(res.data.name); 
      })
      .catch(err =>{
        console.log('Error from Profile');
      });

      console.log(uservalue);

      
  }
    
  };


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOptionChange = (type, value) => {
    this.setState({
      [type]: value
    });
  }

  onSubmit = e => {
    e.preventDefault();
    const params = this.props.params;
    const token = localStorage.getItem("token");
    const u = jwtDecode(token);
    console.log(u.id);
    const data = {
      request_id:params.req_id,
      review:this.state.review,
      rating:this.state.stars,
      user_id:u.id,
      professional_id:params.id
      
    };

    axios
      .post('/api/users/submit-review', data)
      .then(res => {
        this.setState({
          
            review:'',
            rating:''
          
        });
        alert(res.data.msg);
        window.location.href = "/user-completed-requests"; 
      })
      .catch(err => {
        alert(err.data.msg);
        console.log("Error in CreateBook!");
      })
  };
  

  



  

  render() {
    
    
    return (
<div>
      <Headertop/>
	  <div className='container-fluid'>
      <div className='row'>
      <Headeruser/>
		<div className='col-md-10 menu-right'>
    <div className="dashboard-right ">
    <div className="dashboard-cart">
        <h2 className="page-title lang">Submit Review</h2>
<div className="row"><div className="col-md-6">
<form noValidate onSubmit={this.onSubmit}>
<input className='form-control' type="text" name="review" placeholder="Write your review" value={this.state.review} onChange={this.onChange}/><br/>
<div className="rating">
<label>
    <input onChange={() => this.handleOptionChange('stars', '1')} checked={this.state.stars === "1"}  type="radio" name="stars" value="1" />
    <span className="icon">★</span>
  </label>
  <label>
    <input type="radio" onChange={() => this.handleOptionChange('stars', '2')} checked={this.state.stars === "2"} name="stars" value="2" />
    <span className="icon">★</span>
    <span className="icon">★</span>
  </label>
  <label>
    <input type="radio" name="stars" value="3" onChange={() => this.handleOptionChange('stars', '3')} checked={this.state.stars === "3"} />
    <span className="icon">★</span>
    <span className="icon">★</span>
    <span className="icon">★</span>   
  </label>
  <label>
    <input type="radio" name="stars" value="4" onChange={() => this.handleOptionChange('stars', '4')} checked={this.state.stars === "4"} />
    <span className="icon">★</span>
    <span className="icon">★</span>
    <span className="icon">★</span>
    <span className="icon">★</span>
  </label>
  <label>
    <input type="radio" name="stars" value="5" onChange={() => this.handleOptionChange('stars', '5')} checked={this.state.stars === "5"} />
    <span className="icon">★</span>
    <span className="icon">★</span>
    <span className="icon">★</span>
    <span className="icon">★</span>
    <span className="icon">★</span>
  </label>
  </div><br/>
<input name="submit" value="Submit" type="submit" className="button-nav"/>
</form>

</div></div>
        
    </div>
      </div>
</div>
</div>
</div>
</div>


      


    );
  }

  
}

export default () => {
    const params = useParams();
  return (
      <SubmitReview params={params} />
  )
}

//export default ProfessionalApplyRequestForm;