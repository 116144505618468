import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import ReqCard from './ReqCard';
import Header from './Header';
import Headertop from './Headerprotop';
import {getDistance, getPreciseDistance} from 'geolib';


class RequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqqs: [],
      prof:[],
      p_lat: '',
      p_long:'',
      loading:false
    };
  }


  
  componentDidMount() {
    var response_data = [];
    const token = localStorage.getItem("professional-token");
    if(token==null)
    {
      window.location.href = "/professional-logout";
    }
    const ru = jwtDecode(token);
    console.log('test=>'+ru);
    const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
    if(exp_time>3600)
    {
      window.location.href = "/professional-logout";
    }
    axios.get('/api/professionals/get-professional/'+token).then(res => {
      this.setState({
        prof: res.data
       });
               
       if(res.data.otp_verified=="0" || res.data.otp_verified==undefined)
          {
          window.location.href = "/professional-phone-otp/"+ru.id; 
          }
       if(res.data.status==2)
    {
    window.location.href="/disapproved-professional";
    }
    else if(res.data.status==0)
    {
    window.location.href='/waiting-for-approval'; 
    }
    else if(res.data.status==3)
    {
    window.location.href='/waiting-for-profile-review'; 
    }

    axios
    .get('/api/requests/request-list/'+ru.id)
    .then(res1 => {
      if(res1.data.msg)
      {
        this.setState({loading:true, reqqs:''});
        
      }
      console.log(JSON.stringify(res1.data));
      const arr = res1.data;
      console.log(arr);
      arr.map(item=>{
console.log(item.address_1);
const f_address = item.address_1;
console.log(f_address);
var data = {origin:res.data.office_address,destination:f_address};
axios
    .post('/api/professionals/get-distance',data)
    .then(res2=>{
    console.log('distance=>'+res2.data.distance/1000);

    var distance_total = Math.round(res2.data.distance/1000);
  if(res.data.radius_to_cater >= distance_total)
  {

    // Create a new object with the added key-value pair
    const updatedObject = {
      ...item,
      ['distance']: distance_total,
    };

    console.log(updatedObject);
   response_data.push(updatedObject); 
   this.setState({
    reqqs: response_data,
    loading: true
   });
  }
  
    }) // get distance api ends here
    .catch(err1=>{
    console.log('location api not working. '+err1);
    }); // get distance api catch
    }); // request list array map function ends here
      
      
    }) // request list api ends here
    .catch(err =>{
      console.log('Error from ShowBookList');
    }); // request list api catch block

    }) // get professional api ends here
    .catch(err =>{
      console.log('Error from professional');
    }) // catch of get professional api
    

    
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    
    
    

    
      
  };
  

  render() {
    
    const reqqs = this.state.reqqs;
    console.log(reqqs);
    //console.log('render=>'+this.state.p_loc);
    //console.log("PrintBook: " + JSON.stringify(reqqs));
    //alert(reqqs.length);
    let reqList;

    if(!reqqs) {
      reqList = "No Records Found";
    } else {

      reqList = reqqs.map((reqq, k) =>
      <ReqCard reqq={reqq} key={k} />
      );
    }

    return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
        <Header />
        <div className='col-md-10 menu-right'>
      
      <div className="ShowBookList">
        {this.state.loading==true ?(
        <div className="container">
          <br/><br/>

          <div className="list">
             <div className="requestlist">{reqList}</div>
          </div>
        </div>
        ):(
          <div className="container"><div className="list"><div className="row"><div className="col-md-10 menu-right"><div className="dashboard-right thanks_msg"><h3 className="lang lng">Loading...</h3></div></div></div></div></div>
        )}
      </div>
      </div>
      </div>
      </div>
      </div>
      
    );
  }
}

export default RequestList;