import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import axios from 'axios';




const UserReqCardPendingApplied = (props) => {
    const [count, setCount] = useState('No');
    const  reqq  = props.reqq;
    var r_id = reqq._id;
    var p_id = reqq.applications[0].professional_id;
    
    //alert(r_id);

    
        axios
        .get('/api/professionals/get-review/'+r_id+'/'+p_id)
        .then(res => {
          //alert(res.data.review_submitted);
          //window.location.href = "/admin/professionals-list";
          setCount(res.data.review_submitted);
          
        })
        .catch(err =>{
          console.log('Error from Professional List');
        })
    
    return(
        <div className="card-container col-md-12">
           <div className="desc list_container">
                <h1 className="list_title">
                    <Link className="lang" to={`/user-view-request/${reqq._id}`}>
                        { reqq.repair_explanation }
                    </Link>
                    
                </h1>
                <p className="lang">{ reqq.address_1 } </p>
                <Link to={`/user-view-request/${reqq._id}`} className="btn btn-info btn-right language btn-size">
                        View
                    </Link>

                    {count=='No'?(
                <Link to={`/submit-review/`+reqq.applications[0].professional_id+`/`+reqq._id} className="btn btn-info btn-right language btn-size">
                        Submit Review
                    </Link>
                    ) : (
                      <span className="btn btn-right language btn-size">Review Submitted</span>  
                    )}
                    
            </div>

        </div>
    )
};

export default UserReqCardPendingApplied;