import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Headertop from './Headeradmintop';
import Headeruser from './Headeruser';
import {getDistance, getPreciseDistance} from 'geolib';
import Autocomplete from "react-google-autocomplete";

class Request extends Component {
  constructor() {
    super();
    
    this.state = {
      specialisation:'',
      repair_explanation:'',
      repair_immediately:'',
      address_1: '',
      address_2: '',
      country: 'Canada',
      state_or_province: 'Alberta',
      city:'',
      zipcode:'',
      status: '',
      user_id:'',
      request_var:[],
      submitted: false
    };
    
  }

  
  

  componentDidMount() {
    

// Create a script element
const script = document.createElement('script');
const script1 = document.createElement('script');
script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAz8-5HrR3sdCaG2q_BMKYc9AfpULd5t7w&libraries=places';
script1.src = 'script.js';
script.async = true;
script1.async = true;



// Append the script to the document
document.body.appendChild(script);
document.body.appendChild(script1);







    const token = localStorage.getItem("token");
    if(!token)
    {
      window.location.href = "/user-logout";
      
    }
    const u = jwtDecode(token);
    console.log((Math.floor(Date.now()/1000))-u.iat);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/user-logout";
    }
    console.log(u.id);
    
      axios
        .get('/api/users/get-user/'+u.id)
        .then(res => {
          
          console.log(res.data.otp_verified);
          if(res.data.otp_verified=="0" || res.data.otp_verified==undefined)
          {
          window.location.href = "/phone-otp/"+u.id; 
          }
        })
        .catch(err => {
          //alert(err.data.msg);
          alert('error');
          console.log("Error in CreateBook!");
        })   
    
      
  };

  

  onChange = e => {
    //alert('tt');
    var address1 = document.getElementById('autocomplete').value;
    this.setState({ [e.target.name]: e.target.value });
    this.setState({address_1:address1});
      
    
  };
  handleOptionChange = (type, value) => {
    this.setState({
      [type]: value
    });
  }
  


  onSubmit = e => {
    var address1 = document.getElementById('autocomplete').value; 
    //alert(address1);
    //alert(this.state.specialisation);
    //alert(this.state.repair_immediately);
    //alert('clicked');
    const token = localStorage.getItem("token");
    const u = jwtDecode(token);
    e.preventDefault();
//alert(this.state.state_or_province);
    const data = {
          specialisation: this.state.specialisation,
          repair_explanation: this.state.repair_explanation,
          repair_immediately: this.state.repair_immediately,
          address_1: address1,
          address_2: this.state.address_2,
          country: this.state.country,
          state_or_province: this.state.state_or_province,
          city:this.state.city,
          zipcode: this.state.zipcode,
          status: this.state.status,
          user_id: u.id
      
    };
    //alert('clicked');
    //const ff_address = address1+", "+this.state.address_2+", "+this.state.state_or_province+", "+this.state.zipcode;
    const ff_address = address1;
    const uu = this.state.specialisation;
    var i;
    axios
      .post('/api/requests/place', data)
      .then(res => {

        //const ff_address = address1+", "+this.state.address_2+", "+this.state.state_or_province+", "+this.state.zipcode;
        const ff_address = address1;
        console.log('adddress=>'+ff_address);  
        axios.get('/api/professionals/').then(res => {
          console.log(res.data);  
          const p_data = res.data;
          var len = (p_data.length-1);
    
          p_data.map((item, index)=>{  
            i=index;        
          const p_address = item.office_address;
          var smsUpdates;
if(item.sms_updates)
{
  if(item.sms_updates=='yes')
  {
    smsUpdates = 'yes';
  }
  else
  {
    smsUpdates = 'no';
  }
}
else
{
  smsUpdates = 'no';
}
          var data1 = {origin:ff_address,destination:p_address};

          console.log(data1);
          if(item.office_address!=undefined)
          {
axios
      .post('/api/professionals/get-distance',data1)
      .then(res1=>{
        var myString = item.specialisation;
        //alert('specialisation=>'+uu+'adfadf=>'+item.specialisation);
        let result = myString.includes(uu) ? "Yes" : "No";
        //alert(res1.data.distance);
        //alert(item.email);
if(item.radius_to_cater >= res1.data.distance/1000 && result=='Yes')
{
  
  var new_data = {email:item.email, name:item.name, phone:item.phone, sms_updates:smsUpdates, lang:item.language};
  

  axios
      .post('/api/professionals/email-phone', new_data)
      .then(ress=>{
      //alert(ress.data.msg);
      
      });

}
else
{
  
  //alert('outside radius'+ item.radius_to_cater+'===='+res1.data.distance/1000);
}
//window.location.href = "/request-created";      
          })
          .catch(err =>{
            console.log('Error from professional');
          });
          
        }

//alert(i+"====="+len);
if(i==len)
{
  //window.location.href = "/request-created";  
}

        })
      
      
         
      })

    



      


        this.setState({
          specialisation:'',
          repair_explanation:'',
          repair_immediately:'',
          address_1: '',
          address_2: '',
          country: '',
          state_or_province: '',
          city:'',
          zipcode:'',
          status: '',
          user_id:'',
          submitted: true
        });
        //alert(res.data.msg);
         
      })
    
      .catch(err => {
        //alert(err.data.msg);
        console.log("Error in Creating request");
      })
      
     //alert(this.state.address_1);
  };



  render() {
    const submitted = this.state.submitted;
    //alert(submitted);
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
    return (
<div>
      <Headertop/>
	  <div className='container-fluid'>
      <div className='row'>
      
        <Headeruser/>
		<div className='col-md-10 menu-right'>
      { submitted==false ?(
      <div className="dashboard-right">
        <h1 className="lang">Create a Request</h1>

        <form noValidate onSubmit={this.onSubmit} className="request form" encType="multipart/form-data">
          <div className="card-box">
            <h4 className="lang">Personal Information</h4>
            <div className="row form-group">

              <label className="form-label col-md-12 lang">What service do you require?</label>

              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Plumbing Services')} checked={this.state.specialisation === "Plumbing Services"}  className="form-check-input" value="Plumber" /><label className="form-check-label" >Plumbing Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Carpenter Services')} checked={this.state.specialisation === "Carpenter Services"}   className="form-check-input" value="Carpenter" /><label className="form-check-label" >Carpenter Services</label>
                </div>
              </div>

              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Pest Control Services')} checked={this.state.specialisation === "Pest Control Services"}   className="form-check-input" value="Pest Control" /><label className="form-check-label" >Pest Control Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Roofing Services')} checked={this.state.specialisation === "Roofing Services"}   className="form-check-input" value="Roofing" /><label className="form-check-label" >Roofing Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'AC Repair Services')}  checked={this.state.specialisation === "AC Repair Services"}  className="form-check-input" value="AC Repair" /><label className="form-check-label" >AC Repair Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Electrician Services')} checked={this.state.specialisation === "Electrician Services"}  className="form-check-input" value="Electrician" /><label className="form-check-label" >Electrician Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Cleaning and Disinfection')} checked={this.state.specialisation === "Cleaning and Disinfection"}  className="form-check-input" value="Cleaning and Disinfection" /><label className="form-check-label" >Cleaning and Disinfection Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Electrical Appliance Repair')} checked={this.state.specialisation === "Electrical Appliance Repair"}   className="form-check-input" value="Electrical Appliance Repair" /><label className="form-check-label" >Electrical Appliance Repair</label>
                </div>
              </div>

            </div>
            
            <div className="row">
              
              <div className="form-group col-md-12">
                <label className="form-label lang">What do you need to fix?</label>
                <textarea className="form-control" name="repair_explanation" placeholder="Type here" value={this.state.repair_explanation} onChange={this.onChange}></textarea>
              </div>
            </div>
            
            <div className="row form-group">

              <label className="form-label col-md-12 lang">Do you need immediate assistance?</label>

              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input  type="radio"  onChange={() => this.handleOptionChange('repair_immediately', 'Yes')}  checked={this.state.repair_immediately === "Yes"} className="form-check-input" value="Yes"/><label className="form-check-label" >Yes, I need help as soon as possible.</label>
                </div>
                <div className='check-box'>
                  <input type="radio"  className="form-check-input" onChange={() => this.handleOptionChange('repair_immediately', 'No')} checked={this.state.repair_immediately === "No"} value="No" /><label className="form-check-label" >No, I can wait a few days.</label>
                </div>
              </div>
              </div>
            
          </div>
          <div className="card-box">
            <h4 className="lang">Address</h4>

            <div className="row">
              <div className="col-md-12 form-group">
                <label className="form-label lang">Address</label>
                {/*<Autocomplete name="address_1" className="form-control" placeholder="Type Address" value={this.state.address_1} onChange={this.onChange} apiKey="AIzaSyAz8-5HrR3sdCaG2q_BMKYc9AfpULd5t7w" onPlaceSelected={(place) => {
    console.log(place);
    this.setState({address_1:place.formatted_address})
  }} options={{
    language : ['(en)'],
    types: ["(address|establishment)"],
    
  }}
/> */}
                <input id="autocomplete" className="form-control" type="text" name="address_1"  value={this.state.address_1} onChange={this.onChange} />
              
              </div>
            </div>
            {/*<div className="row">
              <div className="col-md-12 form-group">
                <label className="form-label lang">Address 2</label>
                <input className="form-control" type="text" name="address_2" placeholder="Type Address 2" value={this.state.address_2} onChange={this.onChange} />
              </div>
            </div>*/}
            <div className="row">
             {/* <div className="col-md-12 form-group">
                <label className="form-label lang">Country</label>
                <select className="form-control" name="country"  >
                  <option value={this.state.country} onChange={this.onChangeCountry}>Canada</option>
                </select>
          </div> */}
              {/*<div className="col-md-6 form-group">
                <label className="form-label lang">Province</label>
                <select className="form-control" name="state_or_province" value={this.state.state_or_province} onChange={this.onChange} >
                <option value="Alberta" onChange={this.onChange} defaultSelected={this.state.name === "Alberta"}>Alberta</option>
	<option value="British Columbia" onChange={this.onChange} defaultSelected={this.state.name === "British Columbia"}>British Columbia</option>
	<option value="Manitoba" onChange={this.onChange} defaultSelected={this.state.name === "Manitoba"}>Manitoba</option>
	<option value="New Brunswick" onChange={this.onChange} defaultSelected={this.state.name === "New Brunswick"}>New Brunswick</option>
	<option value="Newfoundland and Labrador" onChange={this.onChange} defaultSelected={this.state.name === "Newfoundland and Labrador"}>Newfoundland and Labrador</option>
	<option value="Northwest Territories" onChange={this.onChange} defaultSelected={this.state.name === "Northwest Territories"}>Northwest Territories</option>
	<option value="Nova Scotia" onChange={this.onChange} defaultSelected={this.state.name === "Nova Scotia"}>Nova Scotia</option>
	<option value="Nunavut" onChange={this.onChange} defaultSelected={this.state.name === "Nunavut"}>Nunavut</option>
	<option value="Ontario" onChange={this.onChange} defaultSelected={this.state.name === "Ontario"}>Ontario</option>
	<option value="Prince Edward Island" onChange={this.onChange} defaultSelected={this.state.name === "Prince Edward Island"}>Prince Edward Island</option>
	<option value="Quebec" onChange={this.onChange} defaultSelected={this.state.name === "Quebec"}>Quebec</option>
	<option value="Saskatchewan" onChange={this.onChange} defaultSelected={this.state.name === "Saskatchewan"}>Saskatchewan</option>
	<option value="Yukon" onChange={this.onChange} defaultSelected={this.state.name === "Yukon"}>Yukon</option>
                </select>
          </div>*/}
            </div>
            {/*<div className="row">
              <div className="col-md-6 form-group">
                <label className="form-label lang">City</label>
                <input className="form-control" type="text" name="city" placeholder="Type Here" value={this.state.city} onChange={this.onChange} />
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label lang">Postal Code</label>
                <input className="form-control" type="text" name="zipcode" placeholder="Type Here" value={this.state.zipcode} onChange={this.onChange} />
              </div>
        </div>*/}
            
            <input type="hidden" name="user_id" value=''/><br/>
            <input className="form-control button-nav" name="submit" value="Create Request" type="submit" />
          </div>
          
          
        </form>

      </div>
      ):(
        <div className="dashboard-right thanks_msg">
        <div className="">
                <h3 className="lang">Your Request is Successfully Created!</h3>
<h5 className="lang">You are just moments away from expert help...<br/><a className="btn btn-info" href="/create-request">Create New Request</a></h5>

          </div></div>  
      )}
</div>
</div>
</div>
</div>

      


    );
  }
}

export default Request;