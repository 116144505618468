import React, { Component } from 'react';
import '../App.css';


class ProfessionalLogout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqqs: []
    };
  }

  componentDidMount() {
    localStorage.removeItem("professional-token");
    localStorage.removeItem("p-address");
    window.location.href = "/professional";
    
      
  };
  

  render() {
      return(
          <></>
      )
}
}

export default ProfessionalLogout;