import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import axios from 'axios';


function deleteUser(event)
{
    var deleteUser = window.confirm('Do you want to delete this Professional?');
    if(deleteUser===true)
    {
        //alert('yes');
        var id = event.target.getAttribute('data-id');

        //alert(id);

        axios
        .get('/api/professionals/delete-professional/'+id)
        .then(res => {
          alert(res.data.msg);
          window.location.href = "/admin/professionals-list";
        })
        .catch(err =>{
          console.log('Error from Professional List');
        })
        
    }
}

const ProfessionalCard = (props) => {
    const  reqq  = props.reqq;

    return(
        <div className="card-container col-md-12">
           <div className="desc list_container">
                <h1 className="list_title">
                    <Link to={'/admin/view-professional/'+reqq._id} className="lang">
                        { reqq.name }
                    </Link>
                    
                </h1>
                <p className="lang1">{ reqq.specialisation } </p><p className="lang">{ reqq.createdAt }</p>
                
                 
                  
                 
                 
                 
{(function(){
    if (reqq.status===1) {
        return <><Link to={`/admin/disapprove-professional/${reqq._id}`} className="btn btn-info btn-right lang">disapprove</Link>&nbsp; <span data-id={reqq._id}  onClick={deleteUser} className="lang lang1 btn btn-info btn-right" to={`/delete-user/${reqq._id}`} >Delete</span></>
    } else if (reqq.status===2) {
        return <><Link to={`/admin/approve-professional/${reqq._id}`} className="btn btn-info btn-right lang">Approve</Link>&nbsp;<span data-id={reqq._id}  onClick={deleteUser} className="lang lang1 btn btn-info btn-right" to={`/delete-user/${reqq._id}`}>Delete</span></>
    } else {
        return <><Link to={`/admin/disapprove-professional/${reqq._id}`} className="btn btn-info btn-right lang">disapprove</Link><span className="btn-right">&nbsp;</span><Link to={`/admin/approve-professional/${reqq._id}`} className="btn btn-info btn-right lang">Approve</Link>&nbsp; <span data-id={reqq._id}  onClick={deleteUser} className="btn btn-info btn-right lang lang1">Delete</span></>
    }
    
}).call(this)}
            </div>

        </div>
    )
};

export default ProfessionalCard;