import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from './Header';
import Headertop from './Headerprotop';
import Footer from './Footer';
import jwtDecode from 'jwt-decode';

class UserDashboard extends Component {
  
  constructor() {
    
    //console.log(id);
    
    super();
    this.state = {
      reqqs: '',
      reqqs1:'',
      prof:'',
      invites_recieved:''
    };
    
  }

  componentDidMount() {
    var response_data = [];
    const token = localStorage.getItem("professional-token");
    if(token==null)
    {
      window.location.href = "/professional-logout";
    }
    const ru = jwtDecode(token);
    console.log('test=>'+ru);
    const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
    if(exp_time>3600)
    {
      window.location.href = "/professional-logout";
    }
    
    axios.get('/api/professionals/get-professional/'+token).then(res => {
      this.setState({
        prof: res.data
       });
       //console.log('status=>'+res.data.status);

       if(res.data.otp_verified=="0" || res.data.otp_verified==undefined)
          {
          window.location.href = "/professional-phone-otp/"+ru.id; 
          }

       if(res.data.status==2)
    {
    window.location.href="/disapproved-professional";
    }
    else if(res.data.status==0)
    {
    window.location.href='/waiting-for-approval'; 
    }
    else if(res.data.status==3)
    {
    window.location.href='/waiting-for-profile-review'; 
    }
    
    // Fetch Dashboard Data
    var data1 = {id:ru.id,specialisation:res.data.specialisation, prof_created:res.data.approvedAt, p_address:res.data.office_address, radius:res.data.radius_to_cater};
    axios
      .post('/api/professionals/professional-dashboard-data/', data1)
      .then(res => {
        this.setState({
          reqqs1: res.data
         })
         console.log(res.data);
      })
      .catch(err =>{
        console.log('Error from ShowBookList');
      })

// Fetching Request List
      axios
      .get('/api/requests/request-list/'+ru.id)
      .then(res1 => {
        //alert(res.data._id);
        const arr = res1.data;
        arr.map(item=>{


// Calculation of distance between professional and each request

const f_address = item.address_1;
var data = {origin:res.data.office_address,destination:f_address};
axios
      .post('/api/professionals/get-distance',data)
      .then(res2=>{
      console.log('distance=>'+Math.round(res2.data.distance/1000));
      console.log('ddd=>'+res.data.radius_to_cater);

      var distance_total = Math.round(res2.data.distance/1000);
    if(res.data.radius_to_cater >= distance_total)
    {
     response_data.push(item); 
     this.setState({
      reqqs: response_data
     });
     
    }
    
      })
      .catch(err1=>{
      console.log('location api not working. '+err1);
      }); // Location Api Ends Here
        
      }); //Request list Array map function ends here
        
        
      }) // Request List Api ends here
      .catch(err =>{
        console.log('Error from ShowBookList');
      }); // Request List Catch ends here
     })
    .catch(err =>{
      console.log('Error while getting professional details');
    }) // Professional Fetch Ends Here
    

    


    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    
    
    

      

      
  };

  

  



  

  render() {
    const reqqs = this.state.reqqs;
    console.log(reqqs);
    var itemElements;
    if(!reqqs) {
      itemElements = "there is no requests record!";
    }
    else
    {
     itemElements = reqqs.map((item) => (
      <div key={item._id}>
       <li><Link to={"/view-request/"+item._id}><h6>{item.repair_explanation}</h6><p>{item.specialisation}</p></Link></li>

      </div>
    ));
     }
    return (
<div>
      <Headertop/>
      <div className='container-fluid'>
      <div className='row'>
     <Header/>
        
        
<div className="col-md-10 dashboard">
<div className="row">
<div className="col-md-3 "><div className=" dash_box dash_box1"><span><img src="https://sosblast.com/img/img1.png" width="50"/></span><span><h2>{this.state.reqqs1.job_received}</h2><p>Total Job Invite<br/>Received</p></span><span className="arrow"><img src="https://sosblast.com/img/arrow-right.png" width="30"/></span></div></div>
<div className="col-md-3"><div className="dash_box dash_box2"><span><img src="https://sosblast.com/img/img2.png" width="50"/></span><span><h2>{this.state.reqqs1.job_applied}</h2><p>Total Job Applied</p></span><span className="arrow"><img src="https://sosblast.com/img/arrow-right.png" width="30"/></span></div></div>
<div className="col-md-3"><div className="dash_box dash_box3"><span><img src="https://sosblast.com/img/img3.png" width="50"/></span><span><h2>{this.state.reqqs1.job_accepted}</h2><p>Job Application<br/>Accepted</p></span><span className="arrow"><img src="https://sosblast.com/img/arrow-right.png" width="30"/></span></div></div>
<div className="col-md-3"><div className="dash_box dash_box4"><span><img src="https://sosblast.com/img/img4.png" width="50"/></span><span><h2>{this.state.reqqs1.job_completed}</h2><p>Job Completed</p></span><span className="arrow"><img src="https://sosblast.com/img/arrow-right.png" width="30"/></span></div></div>
</div> 
<div className="row">
<div className="col-md-12"><div className="card-box notification_list"><h5>Latest Requests</h5><ul>
  {itemElements}
  
  </ul></div></div>
{/* <div className="col-md-4"><div className="card-box"><h3>Job Analysis</h3><div id="graph"></div></div></div>   */}
</div> 
</div>
        </div>
        </div>
        <Footer/>
</div>

      


    );
  }

  
}

export default () => {
    const params = useParams();
    //console.log(params);
  return (
      <UserDashboard params={params} />
  )
}

//export default ProfessionalApplyRequestForm;