import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import ReqCard from './ReqCardCompleted';
import Header from './Header';
import Headertop from './Headerprotop';

class CompletedRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqqs: [],
      loading:false
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("professional-token");
    axios.get('/api/professionals/get-professional/'+token).then(res => {
      this.setState({
        prof: res.data
       });
       //console.log('status=>'+res.data.status);
       if(res.data.status==2)
    {
    window.location.href="/disapproved-professional";
    }
    else if(res.data.status==0)
    {
    window.location.href='/waiting-for-approval'; 
    }
    else if(res.data.status==3)
    {
    window.location.href='/waiting-for-profile-review'; 
    }
    })
    .catch(err =>{
      console.log('Error from professional');
    })
    const p_status = localStorage.getItem("p_status");
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    
    const u = jwtDecode(token);
    console.log(u.id);
    axios
      .get('/api/requests/completed-requests/'+u.id)
      .then(res => {
        this.setState({
          reqqs: res.data,
          loading:true
         })
      })
      .catch(err =>{
        console.log('Error from ShowBookList');
      })
      
  };
  

  render() {
    const reqqs = this.state.reqqs;
    //console.log("PrintBook: " + JSON.stringify(reqqs));
    let reqList;

    if(!reqqs || reqqs=='') {
      reqList = "No Records Found";
    } else {
      reqList = reqqs.map((reqq, k) =>
      <ReqCard reqq={reqq} key={k} />
      );
    }

    return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
        <Header />
        <div className='col-md-10 menu-right'>
      
      <div className="ShowBookList">
      {this.state.loading==true ?(
        <div className="container">
          <br/><br/>

          <div className="list">
          <div className="requestlist">{reqList}</div>
          </div>
        </div>
      ):(
<div className="container"><div className="list"><div className="row"><div className="col-md-10 menu-right"><div className="dashboard-right thanks_msg"><h3 class="lang lng">Loading...</h3></div></div></div></div></div>
      )}
      </div>
      </div>
      </div>
      </div>
      </div>
      
    );
  }
}

export default CompletedRequest;