import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Header from './Headeradmin';
import Headertop from './Headeradmintop';

class AdminOrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: []
    };
  }

  componentDidMount() {
   const token = localStorage.getItem("admin_token");
    if(!token)
    {
      window.location.href = "/admin";
    }
    const u = jwtDecode(token);
    console.log(u.id);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/admin/logout";
    }
    
    axios
      .get('/api/orders/view-orders/')
      .then(res => {
        this.setState({
          orders: res.data
         });
      })
      .catch(err =>{
        console.log('Error from Adminorders');
      })
      
      
  };
  

  captureCharge(e){
    let charge_id = e.target.getAttribute("data-id");
    try{
      //alert(this.state.charge_id);
      axios.post("/api/stripe/block-payment-confirm",{
        charge_id: charge_id,
    }).then(res => {
       alert(res.data.message);
  if(res.data.message=='charge_already_captured')
  {
    
  }
      })
      .catch(err => {
        console.log("Error from viewAppDetails");
      });
  
    }catch(error){
      console.log(error);
    }
  }

  render() {
    const orders = this.state.orders;
    var tt = new Date();
    tt.setDate(tt.getDate());
    var dd = Math.floor(new Date(tt).getTime() / 1000);

    return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
        <Header />
        <div className='col-md-10 menu-right'>
      
      <div className="ShowBookList">
        <div className="container">
        
<div className="list">

<h1 className="page-title lang">Order History </h1>

<div className="card-container1 col-md-12">

 <div className="desc list_container">
  
<table id="datatable">
          <tr><th className="lang">User</th><th className="lang">Professional</th><th className="lang">Charges</th><th className="lang">Time Taken</th><th>Capture Amount</th></tr>
   {orders.map(reqq => {
          return <tr><td className="lang">{reqq.userdetails[0].name}</td><td className="lang">{reqq.professionaldetails[0].name}</td><td className="lang">$ {reqq.amount}</td><td className="lang">{reqq.applicationdetails[0].time_of_service} hours</td>
          {reqq.charge_id && reqq.charge_validity>=dd ?<td><button className="btn btn-info" data-id={reqq.charge_id} onClick={this.captureCharge}>Capture</button></td>:<td>Captured Already </td>}
          
          </tr>
            
        })}
   </table>
   </div>
   </div>
</div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
    );
  }
}

export default AdminOrderHistory;