import React, { Component } from 'react';
import '../App.css';
import axios, { Axios } from 'axios';
import { Link } from 'react-router-dom';
import Headertop from './Headeradmintop';

class PthanksSignup extends Component {
  constructor() {
    super();
    this.state = {
      phone:'',
      lang:'en'
    };
  }

  componentDidMount() {
    var lan = localStorage.getItem("lang");
  
    if(lan=='fr')
    {
      this.setState({lang: 'fr'});
    }
    else
    {
      this.setState({lang: 'en'});
    }
  }


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const data = {
      phone: this.state.phone
    };

    axios
      .post('/api/users',data)
      .then(res => {
        this.setState({
          phone: ''
        });
        //alert(res.data.msg);
      })
      .catch(err => {
        alert(err.data.msg);
        console.log("Error in CreateBook!");
      })
  };

  

  render() {
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
var lang=this.state.lang;
    return (
      <div>
      <Headertop/>
      
      <div className='col-md-12 banner-header'><h1 className="lang">{lang=='fr'?(<>Inscription réussie</>):(<>Professional Sign up Successful</>)}</h1></div>
      <div className="middle-section">
        <div className="middle_thanks">
        <div className="row">
          <div className="col-md-12">
          {lang=='fr'?(
            <>
                <h3 className="lang">Félicitations ! Vous êtes désormais membre de la communauté SOS BLAST.</h3>
<h5 className="lang"><b>Vous êtes sur le point d'accéder à un monde d'opportunités.</b><br/>N'attendez plus ! Créez votre profil et faites de chaque urgence une opportunité de croissance pour VOTRE entreprise !<br/><br/><a className="button-nav" href="/update-professional-profile">Créez votre profil</a></h5>
</>):(<><h3 className="lang">Your Sign up is Successful!</h3>
<h5 className="lang"><b>Congratulations! You are now a member of SOS BLAST’s community.</b><br/>You’re now one step closer to accessing a world of opportunities...<br/>Don't wait any longer! Set up your profile and start turning emergencies into opportunities.<br/><br/><a className="button-nav" href="/update-professional-profile">Create Your Profile</a></h5>
</>)}
          </div>
          
        </div>
        </div>
      </div>
      </div>
    );
  }
}

export default PthanksSignup;