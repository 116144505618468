import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Header from './Header';
import Headertop from './Headerprotop';

class ProfessionalOrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: []
    };
  }

  componentDidMount() {
   const token = localStorage.getItem("professional-token");
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    const u = jwtDecode(token);
    console.log(u.id);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/professional-logout";
    }
    
    axios.get('/api/professionals/get-professional/'+token).then(res => {
      this.setState({
        prof: res.data
       });
               
       if(res.data.otp_verified=="0" || res.data.otp_verified==undefined)
          {
          window.location.href = "/professional-phone-otp/"+u.id; 
          }
       if(res.data.status==2)
    {
    window.location.href="/disapproved-professional";
    }
    else if(res.data.status==0)
    {
    window.location.href='/waiting-for-approval'; 
    }
    else if(res.data.status==3)
    {
    window.location.href='/waiting-for-profile-review'; 
    }
    })
    .catch(err =>{
      console.log('Error from professional');
    })


    
    axios
      .get('/api/orders/professional-orders/'+u.id)
      .then(res => {
        this.setState({
          orders: res.data
         });
      })
      .catch(err =>{
        console.log('Error from Professionalorders');
      })
      
      
  };
  

  render() {
    const orders = this.state.orders;
    

    return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
        <Header />
        <div className='col-md-10 menu-right'>
      
      <div className="ShowBookList">
        <div className="container">
        
<div className="list">

<h1 className="page-title lang">Order History </h1>

<div className="card-container1 col-md-12">

 <div className="desc list_container">
  
<table id="datatable">
          <tr><th className="lang">User</th><th className="lang">Charges</th><th className="lang">Time Taken</th><th className="lang">Request</th></tr>
   {orders.map(reqq => {
          return <tr><td className="lang">{reqq.userdetails[0].name}</td><td className="lang">$ {reqq.amount}</td><td className="lang">{reqq.applicationdetails[0].time_of_service} hours</td><td className="lang"><Link to ={`/view-request/${reqq.applicationdetails[0].request_applied}`}>View</Link></td></tr>
            
        })}
   </table>
   </div>
   </div>
</div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
    );
  }
}

export default ProfessionalOrderHistory;