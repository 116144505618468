import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Header from './Headeruser';
import Headertop from './Headeradmintop';

class UserOrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: []
    };
  }

  componentDidMount() {
   const token = localStorage.getItem("token");
    if(!token)
    {
      window.location.href = "/login";
    }
    const u = jwtDecode(token);
    console.log(u.id);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/user-logout";
    }
    
    axios
      .get('/api/orders/user-orders/'+u.id)
      .then(res => {
        this.setState({
          orders: res.data
         });
      })
      .catch(err =>{
        console.log('Error from ServiceList');
      })
      
      
  };
  

  render() {
    const orders = this.state.orders;
    

    return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
        <Header />
        <div className='col-md-10 menu-right'>
      
      <div className="ShowBookList">
        <div className="container">
        
<div className="list">

<h1 className="page-title lang">Order History </h1>

<div className="card-container1 col-md-12">

 <div className="desc list_container">
  
<table id="datatable">
          <tr><th className="lang">Professional</th><th className="lang">Charges</th><th className="lang">Time Taken</th><th className="lang">Request</th></tr>
   {orders.map(reqq => {
          return <tr><td className="lang">{reqq.professionaldetails[0].name}</td><td className="lang">$ {reqq.amount}</td><td className="lang">{reqq.applicationdetails[0].time_of_service} hours</td><td className="lang"><Link to ={`/user-view-request/${reqq.applicationdetails[0].request_applied}`}>View</Link></td></tr>
            
        })}
   </table>
   </div>
   </div>
</div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
    );
  }
}

export default UserOrderHistory;