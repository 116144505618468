import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from './Header';
import jwtDecode from 'jwt-decode';
import Stripe from 'react-stripe-checkout';
import Headertop from './Headeradmintop';
import Headeruser from './Headeruser';

class BlockCard extends Component {
  
  constructor() {
    
    //console.log(id);
    
    super();
    this.state = {
      time_of_service:'',
      fees:'',
      request_applied:'',
      professional_id:'',
      application:[],
      user_id:'',
      charge_id:''
    };
    
  }

  componentDidMount() {

    const token = localStorage.getItem("token");
    if(!token)
    {
      window.location.href = "/user-logout";
      
    }
    const u = jwtDecode(token);
    console.log((Math.floor(Date.now()/1000))-u.iat);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/user-logout";
    }
    console.log(u.id);
    this.setState({
      user_id: u.id
    });
};

  





  

  render() {
    
    //console.log(applications);
    //console.log('test=>'+JSON.stringify(this.props));

    const handleToken = (totalAmount,token)=>{
        try{
          axios.post("/api/stripe/block-payment",{
            token: token.id,
            totalAmount:'100'
        }).then(res => {
            //console.log("Print-showapplicationDetails-API-response: " + JSON.stringify(res.data));
            
            //window.location.href = "/hired-successfully"; 
//alert(res.data.message);
alert(res.data.charge_id);
this.setState({charge_id:res.data.charge_id});
          })
          .catch(err => {
            console.log("Error from viewAppDetails");
          });
    
        }catch(error){
          console.log(error);
        }
      } 

    const tokenHandler = (token)=>{
        //alert('adadf');
        //alert(this.state.fees);
        handleToken(this.state.fees,token);
      }
    

    const handleClick = ()=>{
        try{
            //alert(this.state.charge_id);
            axios.post("/api/stripe/block-payment-confirm",{
              charge_id: this.state.charge_id,
          }).then(res => {
             alert(res.data.message);
        
            })
            .catch(err => {
              console.log("Error from viewAppDetails");
            });
        
          }catch(error){
            console.log(error);
          }
    }

    
    return (
<>
      <Headertop/>
	  <div className='container-fluid'>
      <div className='row'>
      <Headeruser/>
		<div className='col-md-10 menu-right'>
      <div className="dashboard-right ">
      <div className="col-md-6 with_border_right"><h4 className="lang">Pay Using</h4><p><Stripe  stripeKey="pk_test_51KqNoBEsO5lD4LSjMb0558uN5fHcBYw3ANIGpkRx25OOVgRdWffTxNlZTzsvV7PH4ikP18rTuZ8jPQoeeEv2r7oW00MOseB3Be" token={tokenHandler}  /></p></div>
      <div className="col-md-6"><button onClick={handleClick}>Confirm Payment</button></div>
      </div>
</div>
</div>
</div>
</>

      


    );
  }

  
}

export default () => {
    const params = useParams();
    console.log(params);
  return (
      <BlockCard params={params} />
  )
}

//export default ProfessionalApplyRequestForm;