import React, { Component } from 'react';
import '../App.css';
import '../Pro.css';
import '../script.js';
import { Link } from 'react-router-dom';
//import Modal from './Modal';
//import jwtDecode from 'jwt-decode';
import axios from 'axios';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      phone:'',
      sms_updates:'',
      lang:'en'
    };
  }


componentDidMount(){
  var lan = localStorage.getItem("lang");
    if(lan==='fr')
    {
      this.setState({lang: 'fr'});
    }
    else
    {
      this.setState({lang: 'en'});
    }
  const token = localStorage.getItem("professional-token");
  //const ru = jwtDecode(token);
  axios.get('/api/professionals/get-professional/'+token).then(res => {
    this.setState({
      sms_updates: res.data.sms_updates
     });
     
  })
  .catch(err =>{
    console.log('Error from professional');
  })
}
  
  

  render() {
    var lang=this.state.lang;
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
    return (
      <div className="col-md-2 dash_menu dash_menu1">
        
        
        <div className="left_menu">
        <ul><li><Link to="/professional-dashboard" className="lang">{lang==='fr'?(<>tableau de bord</>):(<>Dashboard</>)} </Link></li>
            <li><Link to="/request-list" className="lang">{lang==='fr'?(<>demandes ouvertes</>):(<>Open Requests</>)}</Link></li>
            <li><Link to="/applied-requests" className="lang">{lang==='fr'?(<>Demandes appliquées</>):(<>Applied Requests</>)}</Link></li>
            <li><Link to="/completed-requests" className="lang">{lang==='fr'?(<>Demandes complétées</>):(<>Completed Requests</>)}</Link></li>
            <li><Link to="/professional-order-history" className="lang">{lang==='fr'?(<>Historique des commandes</>):(<>Order History</>)}</Link></li>
            <li><Link to="/view-professional-profile" className="lang">{lang==='fr'?(<>Mon profil</>):(<>My Profile</>)}</Link></li>
            <li><Link to="/update-professional-profile" className="lang">{lang==='fr'?(<>Mettre à jour le profil</>):(<>Update Profile</>)}</Link></li>
            <li><Link to="/professional-logout" className="lang">{lang==='fr'?(<>Se déconnecter</>):(<>Logout</>)}</Link></li>
        </ul>
        </div>
        
        </div>
        
        
    );
  }
}

export default Header;