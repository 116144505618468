import React, { Component, useState} from 'react';
import '../App.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Header from './HeaderUpdateProfile';
import Headertop from './Headerprotop';
import {Link} from 'react-router-dom';



class UpdateProfessionalLicence extends Component {
  constructor() {
    super();
    this.state = {
      submitted:false,
      photo_id: '',
      photo_id1:'',
      registered_address: '',
      office_address: '',
      country: '',
      state_or_province: '',
      radius_to_cater: '',
      professional_card: '',
      status: '0',
      name:'',
      description:'',
      city:'',
      zipcode:'',
      specialisation:'',
      experience:'',
      qualification:'',
      standard_fees:'',
      availability_hours1: '',
      availability_hours2: '',
      specialisations:[],
      card_number:'',
      expireMM:'',
      expireYY:'',
      cvv:'',
      email:'',
      smsUpdates:'',
      phone:'',
      licence:'',
      update_licence:false

    }
   }

  formRef = React.createRef();

  handleAddFields = () => {
    const form = this.formRef.current;

    const newRow = document.createElement('div');
    newRow.className = 'row';

    // Add text input with col-md-4 class
    const newColText = document.createElement('div');
    newColText.className = 'col-md-3';

    const textInput = document.createElement('input');
    textInput.type = 'text';
    textInput.name = 'textValues[]';
    textInput.placeholder = 'Text';
    textInput.className = 'form-control';
    newColText.appendChild(textInput);

    newRow.appendChild(newColText);

    // Add file input with col-md-4 class
    const newColFile = document.createElement('div');
    newColFile.className = 'col-md-5';

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.name = 'fileValues';
    fileInput.className = 'form-control';
    fileInput.setAttribute('multiple', 'multiple'); // Allow multiple file selection
    newColFile.appendChild(fileInput);

    newRow.appendChild(newColFile);

    // Add remove button with col-md-4 class
    const newColRemove = document.createElement('div');
    newColRemove.className = 'col-md-2';

    const removeButton = document.createElement('button');
    removeButton.textContent = 'Remove';
    removeButton.type = 'button';
    removeButton.className = 'btn btn-danger mt-2';
    removeButton.addEventListener('click', () => form.removeChild(newRow));
    newColRemove.appendChild(removeButton);

    newRow.appendChild(newColRemove);

    form.insertBefore(newRow, form.lastChild);
  };






  componentDidMount() {

// Create a script element
// const script = document.createElement('script');
// const script1 = document.createElement('script');
// script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAz8-5HrR3sdCaG2q_BMKYc9AfpULd5t7w&libraries=places';
// script1.src = 'script.js';
// script.async = true;
// script1.async = true;



// Append the script to the document
// document.body.appendChild(script);
// document.body.appendChild(script1);













    const token = localStorage.getItem("professional-token");
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    const ru = jwtDecode(token);
    console.log('test=>'+ru);
    const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
    if(exp_time>3600)
    {
      window.location.href = "/professional-logout";
    }

    axios.get('/api/professionals/get-professional/'+token).then(res => {
      var licence, licenceCount;
      if(res.data.licence=='' || res.data.licence==undefined )
      {
        licence = '';
        licenceCount=0;
      }
      else
      {
        licence = res.data.licence;
        licenceCount = licence.textValues.length;
      }

        this.setState({
          prof: res.data,
          registered_address: res.data.registered_address,
      office_address: res.data.office_address,
      photo_id:licence,
      photo_id1:licence,
      country: res.data.country,
      state_or_province: res.data.state_or_province,
      radius_to_cater: res.data.radius_to_cater,
      professional_card: res.data.professional_card,
      status: res.data.status,
      name:res.data.name,
      description:res.data.description,
      city:res.data.city,
      zipcode:res.data.zipcode,
      experience:res.data.experience,
      qualification:res.data.qualification,
      standard_fees:res.data.standard_fees,
      availability_hours1:res.data.availability_hours1,
      availability_hours2:res.data.availability_hours2,
      card_number:res.data.card_number,
      expireMM:res.data.expireMM,
      expireYY:res.data.expireYY,
      cvv:res.data.cvv,
      email:res.data.email,
      phone:res.data.phone,
      licence:res.data.licence,
      smsUpdates:res.data.sms_updates
         });
         if(res.data.otp_verified==0 || res.data.otp_verified==undefined)
          {
          window.location.href = "/professional-phone-otp/"+ru.id;
          }

         // Handle Checkboxes
         var spec = res.data.specialisation;
         var specArray = spec.split(', ');
         specArray.forEach((value) => {
          const checkbox = document.querySelector(`input[value="${value}"]`);
          if (checkbox) {
            checkbox.checked = true;
            //alert(value);
          }

          const checkbox1 = document.querySelector(`input[name="sms_updates"]`);
        if(this.state.smsUpdates=='yes')
        {
          checkbox1.checked = true;

        }
        });

      })
      .catch(err =>{
        console.log('Error from professional');
      })
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    const u = jwtDecode(token);
    console.log(u.id);


  };


  handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("professional-token");

    const ru = jwtDecode(token);
    const formData = new FormData(this.formRef.current);
    formData.append('id', ru.id);
    console.log('test=>'+formData.getAll('fileValues')[0].name);
//     if(formData.getAll('fileValues')[0].name!='')
//     {
//       console.log(formData.getAll('fileValues')[0].name);
//       //formData.delete(formData.getAll('fileValues')[0]);
//       console.log('ddd');
//       //formData.append('fileValues',formData.getAll('fileValues')[0],null);
//       // Create a Blob with minimal content (e.g., an empty string)
// const emptyContentBlob = new Blob([''], { type: 'text/plain' });

// // Create a File object with the Blob
// const emptyFile = new File([emptyContentBlob], 'emptyFile.txt', { type: 'text/plain' });

// // Append the empty file to FormData
// console.log(emptyFile);
// //formData.append('fileValues', emptyFile);
//     }
    
    
    //console.log(formData.getAll('fileValues')[2].name);
    const response = await fetch('/api/professionals/licenceUpdate', {
      method: 'POST',
      body: formData,
    });
console.log(response)
    if (response.ok) {
      this.setState({submitted:true});
      console.log('Form data saved successfully');
    } else {
      console.error('Error saving form data');
    }
  };

  handleFileChange = (e) => {
alert('sss');
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the image in the state
        this.setState({ photo_id1: reader.result });
      };
      reader.readAsDataURL(file);
      this.setState({ photo_id: file });
    }



  };

  handleCheckboxChange = (event) => {
    const smsUpdates = event.target.checked;
    this.setState({ smsUpdates });
  };


  onChange = e => {
    
    var office_address = document.getElementById('autocomplete').value;
    this.setState({ [e.target.name]: e.target.value });
    this.setState({office_address:office_address});
    var registered_address = document.getElementById('autocomplete1').value;
    this.setState({ [e.target.name]: e.target.value });
    this.setState({registered_address:registered_address});
  };


  
  handleLicenceUpdate=e=>{
    this.setState({submitted:true})
  }


  render() {
  
    //console.log('specialisation=>'+this.state.specialisation);
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
const listItems = [];
if(this.state.licence!=undefined && this.state.licence.textValues!=undefined) {
for (let i = 0; i < this.state.licence.textValues.length; i++) {
  if(i==0)
  {
  listItems.push(<div className="row"><div className="col-md-3"><input type="text" placeholder="Text" value={this.state.licence.textValues[i]} name="textValues[]" className="form-control" /></div><div className="col-md-5"><input type="file"  name="fileValues" className="form-control" multiple /></div><div className="col-md-2"><button type="button" onClick={this.handleAddFields} className="btn btn-success">Add New</button></div><div className="col-md-2"><a href={'/uploads/licence/'+this.state.licence.fileValues[i]}>View Licence File</a></div></div>);
}
else
{
  listItems.push(<div className="row"><div className="col-md-3"><input type="text" placeholder="Text" value={this.state.licence.textValues[i]} name="textValues[]" className="form-control" /></div><div className="col-md-5"><input type="file" name="fileValues" className="form-control" multiple /></div><div className="col-md-2"><button type="button" onClick={this.handleAddFields} className="btn btn-success">Add New</button></div><div className="col-md-2"><a href={'/uploads/licence/'+this.state.licence.fileValues[i]}>View Licence File</a></div></div>);
}
}
}
else
{
  listItems.push(<div className="row">
  <div className="col-md-3">
  <input type="text" placeholder="Text" name="textValues[]" className="form-control" />
  </div>
  <div className="col-md-5">
  <input type="file" name="fileValues" className="form-control" multiple />
  </div>
  <div className="col-md-2">
    <button type="button" onClick={this.handleAddFields} className="btn btn-success">Add New</button>
  </div>
</div>);
}

    return (

<div>
      <Headertop/>
	  <div className='container-fluid'>
      <div className='row'>

        <Header/>
		<div className='col-md-10 menu-right'>
      <div className="dashboard-right">
      <h1 className="lang">Update Your Licence</h1>
      <div className="card-box">

      { this.state.submitted==false ?(
      <form ref={this.formRef} onSubmit={this.handleSubmit} encType='multipart/form-data'>
        {listItems}
        <div className='row'>
          <div className='col-md-2' id="update_licence"><button type="submit" className="btn btn-primary mt-3">Continue with Licence Update</button></div>
          <div className='col-md-6'></div>
          <div className='col-md-2' id="update_licence"><button onClick={this.handleLicenceUpdate} type="submit" className="btn btn-primary mt-3">Continue without Licence Update</button></div>
          </div>
        </form>
      ):(
        <div className="dashboard-right thanks_msg">
        <div className="content-left">
                <h3 className="lang">Licence Successfully Updated!</h3>
<h5 className="lang">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</h5>

          </div></div>
      )}
        </div>

      </div>
</div>
</div>
</div>
</div>


    );
  }
}

export default UpdateProfessionalLicence;