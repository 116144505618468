import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';


class DeleteRequest extends Component {
  constructor(props) {
    super(props);
    
  }

  componentDidMount() {
    //alert('adfadf');
    const params = this.props.params;
    
    axios
        .get('/api/requests/delete-request/'+params.id)
        .then(res => {
          alert('Deleted Successfully');
          
          window.location.href = "/pending-requests"; 
          
        })
        .catch(err => {
          //alert(err.data.msg);
          alert('error');
          console.log("Error in CreateBook!");
        }); 
    
      
  };
  

  render() {
    

    return (
      <></>
      
    );
  }
}

export default () => {
    const params = useParams();
    //console.log(params);
  return (
      <DeleteRequest params={params} />
  )
}