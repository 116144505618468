import React, { Component } from 'react';
import '../../App.css';
import '../../Style.css';
import '../../script.js';
import { Link, useHistory, useParams } from 'react-router-dom';
import Footer from '../Footer';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homes: [],
      services:[],
      adminName:'no'
    };
  }



  componentDidMount() {
    var lang = localStorage.getItem('lang');
    if(lang=='fr')
    {
    window.location.href = '/fr';  
    }
    axios
      .get('/api/pages')
      .then(res => {
        console.log("Print-showapplicationDetails-API-response: " + JSON.stringify(res.data));
        this.setState({
          homes: res.data
        })
      
         
      })
      .catch(err => {
        console.log("Error from viewAppDetails");
      })

      axios
        .get('/api/services')
        .then(res => {
          console.log("Print-services-API-response: " + JSON.stringify(res.data));
          this.setState({
            services: res.data
          })
        })
        .catch(err => {
          console.log("Error from viewAppDetails");
        })
        if(localStorage.getItem("admin_token"))
        {
    this.setState({adminName:localStorage.getItem("admin_name")});
    const token = localStorage.getItem("admin_token");
        if(token)
        {
          //window.location.href = "/admin/professionals-list";
          
        }
        const u = jwtDecode(token);
        console.log(u.id);
      }

  };
  

  render() {
    
const homes = this.state.homes;
const services = this.state.services;
console.log('this=>'+homes);

var data = homes.how_description;
var data1 = homes.how_1_description;
var data2 = homes.how_2_description;
var data3 = homes.how_3_description;

//alert(data1);
if(data!=undefined)
{
console.log(data);
//alert(data);
const decodedData = data.replace(/\n/g, '<br>');
// Display 'decodedData' in your HTML using innerHTML
document.getElementById('how_description').innerHTML = decodedData;
document.getElementById('how_1_description').innerHTML = data1.replace(/\n/g, '<br>');
document.getElementById('how_2_description').innerHTML = data2.replace(/\n/g, '<br>');
document.getElementById('how_3_description').innerHTML = data3.replace(/\n/g, '<br>');
}

    return (
      <>
      
<div className="top-banner">
<div className="container">
<div className="row">
<div className="col-md-12">
<nav className="navbar navbar-expand-lg navbar-light  navbar-fixed-top">
  <div className="container-fluid">
    <a className="navbar-brand" href="#"><img src="/img/logo-final.png" width="90"/></a>
    
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        
      </ul>
      <ul className="nav navbar-nav navbar-right">
        <li className="nav-item"><Link className="nav-link lang" aria-current="page" to="/login">User Login</Link></li>
        <li className="nav-item"><Link to="/login-as-professional" className="lang nav-link">Professional Login</Link></li>
        <li className="nav-item"><Link to="/professional" className="lang nav-link">Join as a Professional</Link></li>
        {/* <li className="nav-item">
        {this.state.adminName=='no' ? (
          <Link className="nav-link lang" to="/login">Sign In</Link>
        ):(
          <Link className="nav-link lang" to="/login">Sign In</Link>
        )}
        </li>
		  <li className="nav-item">
      {this.state.adminName=='no' ? (
          <Link className="nav-link btn btn-info lang" aria-current="page" to="/register">Sign Up </Link>
        ) : (
          <Link className="nav-link btn btn-info lang" aria-current="page" to="/register">Sign Up </Link>
        )}
          
        </li> */}
        <li className="nav-item">
          <button className="en lan" data-id="en"><img src='/en.png'/></button>
          <button className="fr lan" data-id="fr"><img src='/fr.png'/></button></li>
          
          </ul>
    </div>
  </div>
</nav>
	
	
</div>

	</div>
</div>
<div className="center-div">
<h2>
        <span>{homes.banner_h1}</span>
      </h2>	
<h1><span>{homes.banner_h2}</span></h1>
<h3>
        <span>{homes.banner_h3}</span>
      </h3>
<a href={homes.banner_link} className="btn btn-info">Get Help Now</a>
</div>
</div>
<div className="banner-bottom">
<div className="container">
<div className="row">
<div className="col-md-3">
<div className="icon-div">
<img src="../img/icon1.png"/><h4>Professional<br/>Services</h4>
</div>
</div>	
<div className="col-md-3">
<div className="icon-div">
<img src="img/icon2.png"/><h4>Instant<br/>Quote</h4>
</div>
</div>	
<div className="col-md-3 third">
<div className="icon-div">
<img src="img/icon3.png"/><h4>24/7 Availabilities</h4>
</div>
</div>	
<div className="col-md-3">
<div className="icon-div">
<img src="img/icon4.png"/><h4>Experienced<br/>and Reliable</h4>
</div>
</div>	
</div>
</div>	
</div>
<div className="how_we_work">
<div className="container">
<h2>
        <span>{homes.how_heading}</span>
      </h2>
<h5>
        <span id="how_description"></span>
      </h5>
<div className="row">
<div className="col-md-4">
<div className="feature">
<div className="image">
<img src="img/feature1.png"/>
</div>
<h4>{homes.how_1_heading}</h4>
<p id="how_1_description">{homes.how_1_description}</p>
</div>	
</div>	
<div className="col-md-4">
<div className="feature">
<div className="image img2">
<img src="img/feature2.png"/>
</div>
<h4>{homes.how_2_heading}</h4>
<p id="how_2_description">{homes.how_2_description}</p>
</div>	
</div>	
<div className="col-md-4">
<div className="feature">
<div className="image">
<img src="img/feature3.png"/>	
</div>
<h4>{homes.how_3_heading}</h4>
<p id="how_3_description">{homes.how_3_description}</p>
</div>
</div>	

	</div>
</div>	
</div>
<div className="services">
<div className="container">
<h2>{homes.services_heading}</h2>
<h5>{homes.services_description}</h5>
<div className="row">
{services.map(service => {
          return <div className="col-md-3">
          <div className="service">
            <img src={`img/${service.icon}`}/>
            <h6>{service.name}</h6>
            <p>{service.description}</p>
            </div></div>
        })}





</div>
</div>
</div>
<div className="mid-section">
<div className="container">
<div className="row">
<div className="col-md-8">
<div className="content">
<h3>{homes.section_1_heading}</h3>
<p>
{homes.section_1_description}
</p>
<a href={homes.section_1_link} className="btn btn-info">Get Help Now</a>
</div>	
</div>
<div className="col-md-4">
<div className="img">
<img src="img/img.png"/>	
</div>
</div>	
</div>
</div>	
</div>
<div className="services customer_satisfaction">
<div className="container">
<h2>Your Satisfaction, Our Commitment</h2>
<h5>Don't let unexpected home or business emergencies disrupt your peace of mind. Trust SOS BLAST to swiftly connect you with skilled professionals who care about your comfort and safety.</h5>
<div className="row">
<div className="col-md-4">
<div className="service customer">
<img src="img/consumer1.png"/>
<h1>300+</h1>
<p>Verfied Professionals</p>
</div>
</div>
<div className="col-md-4">
<div className="service customer">
<img src="img/consumer2.png"/>
<h1>3500</h1>
<p>Job Successfully Completed</p>
</div>
</div>
<div className="col-md-4">
<div className="service customer">
<img src="img/consumer3.png"/>
<h1>98%</h1>
<p>Customer<br/>Satisfaction</p>
</div>
</div>
</div>
</div>
</div>
<Footer/>

      </>
        
        
    );
  }
}

export default Home;