import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Link, useParams } from 'react-router-dom';
import Headertop from './Headeradmintop';
import Headeruser from './Headeruser';

class UserViewProfessionalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqqs: [],
      reviews:[]
    };
  }

  componentDidMount() {
    const params = this.props.params;
    const token = localStorage.getItem("token");
    if(!token)
    {
      window.location.href = "/login";
    }
    const u = jwtDecode(token);
    console.log(u.id);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/user-logout";
    }
    axios
      .get('/api/professionals/view-profile/'+params.id)
      .then(res => {
        this.setState({
          reqqs: res.data
         })
         
      })
      .catch(err =>{
        console.log('Error from Profile');
      })

      axios
      .get('/api/users/get-reviews/'+params.id)
      .then(res => {
        this.setState({
          reviews: res.data
         })
       console.log(res.data);  
      })
      .catch(err =>{
        console.log('Error from Profile');
      })
      
  };
  

  render() {
    const reqqs = this.state.reqqs;
    const reviews = this.state.reviews;
    //console.log("PrintBook: " + JSON.stringify(reqqs));
    
if(reqqs)
{
    return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
      <Headeruser />
        <div className='col-md-10 menu-right'>
      
        <div className="dashboard-right ">
        <h2 className="page-title lang">Professional Profile</h2>
    <div className="dashboard-cart">
        
<div className="row">
  <div className="col-md-7">
    <h4><img src={"/uploads/"+reqqs.photo_id} width="80"/><span className="lang">{reqqs.name}</span></h4>
    <h4 className="lang">About me</h4>
    <p className="lang">{reqqs.description}</p>
    
    </div>
    <div className="col-md-5 profile_right">
    <h4 className="lang">Professional Information</h4>
    {/* <p><strong className="lang">Email:</strong> <span className="lang">{reqqs.email}</span></p> */}
    <p><strong className="lang">Radius to Cater:</strong> <span className="lang">{reqqs.radius_to_cater} kms</span></p>
    <p><strong className="lang">Experience:</strong> <span className="lang">{reqqs.experience} years</span></p>
    <p><strong className="lang">Standard Fees:</strong> <span className="lang">${reqqs.standard_fees}</span></p>
    {/* <h4 className="lang">Basic Information</h4>
      <p><strong className="lang">Office Address:</strong> <span className="lang">{reqqs.office_address}</span></p>
      <p><strong className="lang">Radius to Cater:</strong> <span className="lang">{reqqs.radius_to_cater}</span></p>
      <p><strong className="lang">Registered Address:</strong> <span className="lang">{reqqs.registered_address}</span></p> */}
      </div>
   
  </div>
  <div className="row">
    <div className="col-md-12">
    <div className="reviewTop"><h2>Reviews</h2>
    {/* <span className="btn btn-success"><Link to={"/submit-review/"+reqqs._id}>Submit Review</Link></span> */}
    </div>
      {reviews.map(review => {
           return <div className="reviewList"><h4>{review.users[0].name}{review.rating == 1 && <span><span class="fa fa-star checked"></span>
           <span class="fa fa-star "></span>
           <span class="fa fa-star "></span>
           <span class="fa fa-star"></span>
           <span class="fa fa-star"></span></span> || review.rating == 2 && <span><span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star "></span>
           <span class="fa fa-star"></span>
           <span class="fa fa-star"></span></span> || review.rating == 3 && <span><span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star"></span>
           <span class="fa fa-star"></span></span> || review.rating == 4 && <span><span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star"></span></span> || review.rating == 5 && <span><span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span>
           <span class="fa fa-star checked"></span></span>}</h4><p>{review.review}</p></div>
            
        })}
    </div>
  </div>
        
    </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
    )
    }
    else
    {
      return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
      <Headeruser />
        <div className='col-md-10 menu-right'>
      
        <div className="dashboard-right ">
        <h2 className="page-title lang">No Professional Found</h2>
    
      </div>
      </div>
      </div>
      </div>
      </div>
      )
    }
  }
}


//export default UserViewProfessionalProfile;

export default () => {
    const params = useParams();
    //console.log(params);
  return (
      <UserViewProfessionalProfile params={params} />
  )
}