import React, { Component } from 'react';
import '../App.css';
import axios, { Axios } from 'axios';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Stripe from 'react-stripe-checkout';
import Headermain from './Headermain';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import FacebookLogin from 'react-facebook-login';

class UserRegister extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email:'',
      password:''
    };
  }
  componentDidMount() {
    if(localStorage.getItem("lang"))
    {
      if(localStorage.getItem("lang")=='fr')
      {
       //alert(localStorage.getItem("lang"));
       window.location.href = "/fr/register";
      }
    }

    // Google Login
    const clientId ="102143246081-iqodvc2om9ecjrcgdecfindnmpicu06i.apps.googleusercontent.com";
    const initClient = () => {
      gapi.client.init({
      clientId: clientId,
      scope: ''
    });
 };
 gapi.load('client:auth2', initClient);
    if(localStorage.getItem("token"))
    {

    
    const token = localStorage.getItem("token");
    if(token)
    {
      window.location.href = "/create-request";
    }
    const u = jwtDecode(token);
    console.log(u.id);
  }
}

responseFacebook=response=> {
  console.log(response);
  axios
    .post('/api/users/fb-login', {email:response.email, name:response.name})
    .then(res => {
      this.setState({
        email:'',
        password:''
      });
      //alert(res.status);
      if(res.status==200)
      {
      alert(res.data.msg);
      window.location.href = "/user-dashboard"; 
      //window.location.href = "http://localhost:3000/create-request"; 
      }
      else
      {
        alert(res.err);
      }
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('username', res.data.name);
    })
    .catch(err => {
      alert("Error=>"+err);
      console.log("Error in CreateBook!");
    })
  
}
componentClicked=response=> {
  console.log(response);
}



responseSuccess = res => {
  console.log(res.tokenId);
  axios
    .post('/api/users/google-login', {token:res.tokenId})
    .then(res => {
      this.setState({
        email:'',
        password:''
      });
      //alert(res.status);
      if(res.status==200)
      {
      alert(res.data.msg);
      window.location.href = "/create-request"; 
      //window.location.href = "http://localhost:3000/create-request"; 
      }
      else
      {
        alert(res.err);
      }
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('username', res.data.name);
    })
    .catch(err => {
      alert("Error=>"+err);
      console.log("Error in CreateBook!");
    })

}

responseFailure = res =>{
  console.log('fail=>'+res);

}


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const data = {
      name: this.state.name,
      email:this.state.email,
      password:this.state.password,
      otp_verified:"0"
    };

    axios
      .post('/api/users',data)
      .then(res => {
        this.setState({
          name: '',
          email:'',
          password:''
        });
        //alert(res.data.msg);
        if(res.status==200)
        {
        console.log(res.data.id);
        localStorage.setItem('token', res.data.token);
      localStorage.setItem('username', res.data.name);
        window.location.href = "/phone-otp/"+res.data.id; 
        }
        else
        {
          alert(res.data.msg);
        }
      })
      .catch(err => {
        alert(err.data.msg);
        console.log("Error in CreateBook!");
      })
  };

  

  render() {
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
    return (
      <div>
      <Headermain/>
      
      <div className='col-md-12 banner-header'><h1 className="lang">SOS BLAST - Your Emergency Home & Business Solution</h1><p className="lang">Instantly connect with qualified professionals for swift and effective issue resolution.</p></div>
      <div className="middle-section">
        <div className="middle">
        <div className="row">
          <div className="col-md-5 left_section">
            <div className="content-left">
<h3 className="lang">Already have an account?</h3><br/>
{/* <p className="lang">To keep connected with us please<br/>login with your personal info </p> */}
<Link to="/login" className="button-nav lang">
                  Sign In Here
              </Link>
          </div></div>
          <div className="col-md-7 right_section">
          <div className="content-right">
          <h3 className="lang">New to SOS BLAST?<br/>Create an Account</h3>
<div className="social">
    <li><GoogleLogin 
    clientId="102143246081-iqodvc2om9ecjrcgdecfindnmpicu06i.apps.googleusercontent.com" buttonText=""  
    className ={"google_login"}
    icon = {true}
    onSuccess={this.responseSuccess}
    onFailure ={this.responseFailure}
    /></li></div>
<div className="form-register">
  {/* <p className="lang">or use your email for registration :</p> */}
<form noValidate onSubmit={this.onSubmit}>
<input type="text" name="name" placeholder="Name" value={this.state.name} onChange={this.onChange}/>
<input type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.onChange}/>
<input type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.onChange}/>
<input name="submit" value="Sign up" type="submit" className="button-nav"/>
</form>

</div>
            </div>
          </div>
          <div className='col-md-12 center_text'><p>Are you a professional? <Link to="/register-as-professional">Register</Link> or <Link to="/login-as-professional">Login</Link> here.</p></div>
        </div>
        </div>
      </div>
      </div>
    );
  }
}

export default UserRegister;