import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const ReqCard = (props) => {
    const  reqq  = props.reqq;

    return(
        <>
        {reqq.requestdetails[0]!==undefined ? (
        <div className="card-container col-md-12">
           <div className="desc list_container">
                <h1 className="list_title">
                    <Link className="lang" to={`/view-request/${reqq.requestdetails[0]._id}`}>
                        { reqq.requestdetails[0].repair_explanation }
                    </Link>
                    
                </h1>
                <p className="lang">Time Duration: {reqq.time_of_service} hrs</p>
                <p className="lang">Charges: ${reqq.fees}</p>
                <Link to={`/view-request/${reqq.requestdetails[0]._id}`} className="btn btn-info btn-right lang">
                        View
                    </Link>
            </div>

        </div>
        ):(
           <></>
        )}
        </>
    )
};

export default ReqCard;