import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import Headermain from './Headermain';

class FrProfessionalRegister extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email:'',
      password:''
    };
  }

  componentDidMount() {
   
    if(localStorage.getItem("lang"))
    {
      if(localStorage.getItem("lang")=='en')
      {
       //alert(localStorage.getItem("lang"));
       window.location.href = "/register-as-professional";
      }
    }

    if(localStorage.getItem("professional-token"))
    {

    
    const token = localStorage.getItem("professional-token");
    if(token)
    {
      window.location.href = "/update-professional-profile";
    }
    const u = jwtDecode(token);
    console.log(u.id);
  }
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    if(localStorage.getItem("lang")==null)
    {
      localStorage.setItem("lang",'en');
    }
    alert(localStorage.getItem("lang"));
    const data = {
      name: this.state.name,
      email:this.state.email,
      password:this.state.password,
      lang: localStorage.getItem("lang")
    };

    axios
      .post('/api/professionals',data)
      .then(res => {
        this.setState({
          name: '',
          email:'',
          password:''
        });
        //alert(res.data.msg);
        if(res.status===200)
        {
          localStorage.setItem('professional-token', res.data.token);
        localStorage.setItem('p_name', res.data.name);
        localStorage.setItem('p_status',res.data.status);
        localStorage.setItem('d_reason',res.data.d_reason);
        const f1_address = res.data.f1_address;
        console.log(f1_address);
        localStorage.setItem('p-address',f1_address);
        window.location.href = "/professional-phone-otp/"+res.data.id; 
        
        }
        else
        {
          alert(res.data.msg);
        }
      })
      .catch(err => {
        
        console.log("Error in CreateBook!");
      })
  };

  

  render() {
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
    return (
      <div>
      <Headermain/>
      <div className='col-md-12 banner-header'><h1 className="lang">Faites évoluer votre entreprise avec SOS BLAST</h1><p className="lang">Répondez aux urgences. Gonflez vos revenus. Gardez chaque dollar gagné.</p></div>
      <div className="middle-section">
      
        <div className="middle">
        
        <div className="row">
          
          <div className="col-md-5 left_section left_section_p">
            <div className="content-left">
<h3 className="lang">Déjà enregistré?</h3>
<p className="lang">Accédez à votre compte professionnel ici.</p>
<Link to="/login-as-professional" className="button-nav lang">
Se connecter
              </Link>
          </div></div>
          <div className="col-md-7 right_section right_section_p">
          <div className="content-right">
          <h1 className="lang">Inscrivez-vous en tant que professionnel</h1>
<div className="form-register">
  {/* <p className="lang">or use your email for registration :</p> */}
<form noValidate onSubmit={this.onSubmit}>
<input type="text" name="name" placeholder="Nom" value={this.state.name} onChange={this.onChange}/>
<input type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.onChange}/>
<input type="password" name="password" placeholder="Mot de passe" value={this.state.password} onChange={this.onChange}/>
<input name="submit" value="S’inscrire" type="submit" className="button-nav"/>
</form>

</div>
            </div>
          </div>
          <div className='col-md-12 center_text'><p>Besoin urgent d’assistance? <Link to="/register">Obtenez de l’aide dès maintenant</Link></p></div>
        </div>
        </div>
      </div>
      </div>
    );
  }
}

export default FrProfessionalRegister;