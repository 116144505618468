import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Headertop from './Headeradmintop';
import Headeruser from './Headeruser';
import {getDistance, getPreciseDistance} from 'geolib';

class EditRequest extends Component {
  constructor() {
    super();
    
    this.state = {
      specialisation:'',
      repair_explanation:'',
      repair_immediately:'',
      address_1: '',
      address_2: '',
      country: 'Canada',
      state_or_province: 'Alberta',
      city:'',
      zipcode:'',
      status: '',
      user_id:'',
      request_var:[]
    };
    
  }

  
  

  componentDidMount() {
    
    const token = localStorage.getItem("token");
    if(!token)
    {
      window.location.href = "/";
      
    }
    const u = jwtDecode(token);
    console.log((Math.floor(Date.now()/1000))-u.iat);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/user-logout";
    }
    console.log(u.id);
    
      axios
        .get('/api/users/get-user/'+u.id)
        .then(res => {
          
          console.log(res.data.otp_verified);
          if(res.data.otp_verified=="0" || res.data.otp_verified==undefined)
          {
          window.location.href = "/phone-otp/"+u.id; 
          }
        })
        .catch(err => {
          //alert(err.data.msg);
          alert('error');
          console.log("Error in CreateBook!");
        })   
    
        const params = this.props.params; 
        
axios.get('/api/requests/'+params.id)
.then(res=>{
//alert('fetched');
//alert(res.data);

this.setState({
    specialisation:res.data.specialisation,
    repair_explanation:res.data.repair_explanation,
    repair_immediately:res.data.repair_immediately,
    address_1: res.data.address_1,
    address_2: res.data.address_2,
    country: 'Canada',
    state_or_province: res.data.state_or_province,
    city:res.data.city,
    zipcode:res.data.zipcode,
    status: res.data.status,
    user_id:res.data.user_id,
    request_var:[]
});
})
.catch(err=>{
alert('Something went wrong '+err);
});

  };

  

  onChange = e => {
    //alert('tt');
    this.setState({ [e.target.name]: e.target.value });
    
      
    
  };
  handleOptionChange = (type, value) => {
    this.setState({
      [type]: value
    });
  }
  


  onSubmit = e => {
    //alert(this.state.specialisation);
    const token = localStorage.getItem("token");
    const u = jwtDecode(token);
    const params = this.props.params;
    e.preventDefault();
    const data = {
          specialisation: this.state.specialisation,
          repair_explanation: this.state.repair_explanation,
          repair_immediately: this.state.repair_immediately,
          address_1: this.state.address_1,
          address_2: this.state.address_2,
          country: this.state.country,
          state_or_province: this.state.state_or_province,
          city:this.state.city,
          zipcode: this.state.zipcode,
          status: this.state.status,
          user_id: u.id
      
    };
    //alert('clicked');
    //const ff_address = this.state.address_1+", "+this.state.address_2+", "+this.state.state_or_province+", "+this.state.zipcode;
    //const uu = this.state.specialisation;
    var url = '/api/requests/update/'+params.id;
    //alert(url);
    axios
      .post(url, data)
      .then(res => {
       alert('Request Updated');
       //window.location.href = "/request-created"; 
      })
    
      .catch(err => {
        alert('Something went wrong');
        console.log("Error in Creating request");
      })
  };



  render() {
    
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
    return (
<div>
      <Headertop/>
	  <div className='container-fluid'>
      <div className='row'>
      
        <Headeruser/>
		<div className='col-md-10 menu-right'>
      <div className="dashboard-right">
        <h1 className="lang">Create a Request</h1>

        <form noValidate onSubmit={this.onSubmit} className="request form" encType="multipart/form-data">
          <div className="card-box">
            <h4 className="lang">Professional Information</h4>
            <div className="row form-group">

              <label className="form-label col-md-12 lang">What Service do you Required?</label>

              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Plumbing Services')} checked={this.state.specialisation === "Plumbing Services"}  className="form-check-input" value="Plumber" /><label className="form-check-label" >Plumbing Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Carpenter Services')} checked={this.state.specialisation === "Carpenter Services"}   className="form-check-input" value="Carpenter" /><label className="form-check-label" >Carpenter Services</label>
                </div>
              </div>

              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Pest Control Services')} checked={this.state.specialisation === "Pest Control Services"}   className="form-check-input" value="Pest Control" /><label className="form-check-label" >Pest Control Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Roofing Services')} checked={this.state.specialisation === "Roofing Services"}   className="form-check-input" value="Roofing" /><label className="form-check-label" >Roofing Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'AC Repair Services')}  checked={this.state.specialisation === "AC Repair Services"}  className="form-check-input" value="AC Repair" /><label className="form-check-label" >AC Repair and Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Electrician Services')} checked={this.state.specialisation === "Electrician Services"}  className="form-check-input" value="Electrician" /><label className="form-check-label" >Electrician Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Cleaning and Disinfection')} checked={this.state.specialisation === "Cleaning and Disinfection"}  className="form-check-input" value="Cleaning and Disinfection" /><label className="form-check-label" >Cleaning and Disinfection Services</label>
                </div>
              </div>
              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input type="radio" onChange={() => this.handleOptionChange('specialisation', 'Electrical Appliance Repair')} checked={this.state.specialisation === "Electrical Appliance Repair"}   className="form-check-input" value="Electrical Appliance Repair" /><label className="form-check-label" >Electrical Appliance Repair</label>
                </div>
              </div>

            </div>
            
            <div className="row">
              
              <div className="form-group col-md-12">
                <label className="form-label lang">Explain in Brief What You Needs To Be Repaired?</label>
                <textarea className="form-control" name="repair_explanation" placeholder="Type here" value={this.state.repair_explanation} onChange={this.onChange}></textarea>
              </div>
            </div>
            
            <div className="row form-group">

              <label className="form-label col-md-12 lang">Do you Need the Repair Immediately?</label>

              <div className="form-check col-md-12 form-check-radio">
                <div className='check-box'>
                  <input  type="radio"  onChange={() => this.handleOptionChange('repair_immediately', 'Yes')}  checked={this.state.repair_immediately === "Yes"} className="form-check-input" value="Yes"/><label className="form-check-label" >Yes</label>
                </div>
                <div className='check-box'>
                  <input type="radio"  className="form-check-input" onChange={() => this.handleOptionChange('repair_immediately', 'No')} checked={this.state.repair_immediately === "No"} value="No" /><label className="form-check-label" >No</label>
                </div>
              </div>
              </div>
            
          </div>
          <div className="card-box">
            <h4 className="lang">Address</h4>

            <div className="row">
              <div className="col-md-12 form-group">
                <label className="form-label lang">Address 1</label>
                <input className="form-control" type="text" name="address_1" placeholder="Type Address 1" value={this.state.address_1} onChange={this.onChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label className="form-label lang">Address 2</label>
                <input className="form-control" type="text" name="address_2" placeholder="Type Address 2" value={this.state.address_2} onChange={this.onChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="form-label lang">Country</label>
                <select className="form-control" name="country"  >
                  <option value={this.state.country} onChange={this.onChangeCountry}>Canada</option>
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label lang">Province</label>
                <select className="form-control" name="state_or_province" value={this.state.state_or_province} onChange={this.onChange} >
                <option value="Alberta" onChange={this.onChange} defaultSelected={this.state.name === "Alberta"}>Alberta</option>
	<option value="British Columbia" onChange={this.onChange} defaultSelected={this.state.name === "British Columbia"}>British Columbia</option>
	<option value="Manitoba" onChange={this.onChange} defaultSelected={this.state.name === "Manitoba"}>Manitoba</option>
	<option value="New Brunswick" onChange={this.onChange} defaultSelected={this.state.name === "New Brunswick"}>New Brunswick</option>
	<option value="Newfoundland and Labrador" onChange={this.onChange} defaultSelected={this.state.name === "Newfoundland and Labrador"}>Newfoundland and Labrador</option>
	<option value="Northwest Territories" onChange={this.onChange} defaultSelected={this.state.name === "Northwest Territories"}>Northwest Territories</option>
	<option value="Nova Scotia" onChange={this.onChange} defaultSelected={this.state.name === "Nova Scotia"}>Nova Scotia</option>
	<option value="Nunavut" onChange={this.onChange} defaultSelected={this.state.name === "Nunavut"}>Nunavut</option>
	<option value="Ontario" onChange={this.onChange} defaultSelected={this.state.name === "Ontario"}>Ontario</option>
	<option value="Prince Edward Island" onChange={this.onChange} defaultSelected={this.state.name === "Prince Edward Island"}>Prince Edward Island</option>
	<option value="Quebec" onChange={this.onChange} defaultSelected={this.state.name === "Quebec"}>Quebec</option>
	<option value="Saskatchewan" onChange={this.onChange} defaultSelected={this.state.name === "Saskatchewan"}>Saskatchewan</option>
	<option value="Yukon" onChange={this.onChange} defaultSelected={this.state.name === "Yukon"}>Yukon</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="form-label lang">City</label>
                <input className="form-control" type="text" name="city" placeholder="Type Here" value={this.state.city} onChange={this.onChange} />
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label lang">Postal Code</label>
                <input className="form-control" type="text" name="zipcode" placeholder="Type Here" value={this.state.zipcode} onChange={this.onChange} />
              </div>
            </div>
            <input type="hidden" name="user_id" value=''/><br/>
            <input className="form-control button-nav" name="submit" value="Update Request" type="submit" />
          </div>
          
          
        </form>

      </div>
</div>
</div>
</div>
</div>

      


    );
  }
}

export default () => {
    const params = useParams();
    //console.log(params);
  return (
      <EditRequest params={params} />
  )
}