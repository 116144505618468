import React, { Component, useState} from 'react';
import '../App.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Header from './HeaderUpdateProfile';
import Headertop from './Headerprotop';
import {Link} from 'react-router-dom';



class UpdateProfessionalProfile extends Component {
  constructor() {
    super();
    this.state = {
      photo_id: '',
      photo_id1:'',
      registered_address: '',
      office_address: '',
      country: '',
      state_or_province: '',
      radius_to_cater: '',
      professional_card: '',
      status: '0',
      name:'',
      description:'',
      city:'',
      zipcode:'',
      specialisation:'',
      experience:'',
      qualification:'',
      standard_fees:'',
      availability_hours1: '',
      availability_hours2: '',
      specialisations:[],
      card_number:'',
      expireMM:'',
      expireYY:'',
      cvv:'',
      email:'',
      smsUpdates:'',
      phone:'',
      submitted:0,
      licence:'',
      lang:'en'
        
    }
    
    
  }




  removeRow = (event) => {
    const parentRow = event.target.closest('.row');

    if (parentRow) {
      parentRow.remove();
    }
  };





  formRef = React.createRef();

  handleAddFields = () => {
    const form = this.formRef.current;

    const newRow = document.createElement('div');
    newRow.className = 'row';

    // Add text input with col-md-4 class
    const newColText = document.createElement('div');
    newColText.className = 'col-md-3';

    const textInput = document.createElement('input');
    textInput.type = 'text';
    textInput.name = 'textValues[]';
    textInput.placeholder = 'Text';
    textInput.className = 'form-control';
    newColText.appendChild(textInput);

    newRow.appendChild(newColText);

    // Add file input with col-md-4 class
    const newColFile = document.createElement('div');
    newColFile.className = 'col-md-5';

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.name = 'fileValues';
    fileInput.className = 'form-control';
    fileInput.setAttribute('multiple', 'multiple'); // Allow multiple file selection
    newColFile.appendChild(fileInput);

    newRow.appendChild(newColFile);

    // Add remove button with col-md-4 class
    const newColRemove = document.createElement('div');
    newColRemove.className = 'col-md-2';

    const removeButton = document.createElement('button');
    removeButton.textContent = 'Remove';
    removeButton.type = 'button';
    removeButton.className = 'btn btn-danger mt-2';
    removeButton.addEventListener('click', () => form.removeChild(newRow));
    newColRemove.appendChild(removeButton);

    newRow.appendChild(newColRemove);

    form.insertBefore(newRow, form.lastChild);
  };

  
  
  componentDidMount() {

// Create a script element
const script = document.createElement('script');
const script1 = document.createElement('script');
script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAz8-5HrR3sdCaG2q_BMKYc9AfpULd5t7w&libraries=places';
script1.src = 'script.js';
script.async = true;
script1.async = true;



// Append the script to the document
document.body.appendChild(script);
document.body.appendChild(script1);
//document.getElementById('update_licence').style.display = "none";











    var lan = localStorage.getItem("lang");
    //alert(lan);
    if(lan=='fr')
    {
      this.setState({lang: 'fr'});
    }
    else
    {
      this.setState({lang: 'en'});
    }
    const token = localStorage.getItem("professional-token");
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    const ru = jwtDecode(token);
    console.log('test=>'+ru);
    const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
    if(exp_time>3600)
    {
      window.location.href = "/professional-logout";
    }

    axios.get('/api/professionals/get-professional/'+token).then(res => {
      var photo, pic;
      if(res.data.photo_id=='' || res.data.photo_id==undefined )
      {
         photo = '/uploads/avatar-3637425_640.jpg';
      }
      else
      {
        photo = "/uploads/"+res.data.photo_id;
        pic = res.data.photo_id;
      }
      //alert(res.data.email);

      var licence, licenceCount;
      if(res.data.licence=='' || res.data.licence==undefined )
      {
        licence = '';
        licenceCount=0;
      }
      else
      {
        licence = res.data.licence;
        licenceCount = licence.textValues.length;
      }
        this.setState({
          prof: res.data,
          registered_address: res.data.registered_address,
      office_address: res.data.office_address,
      photo_id:pic,
      photo_id1:photo,
      country: res.data.country,
      state_or_province: res.data.state_or_province,
      radius_to_cater: res.data.radius_to_cater,
      professional_card: res.data.professional_card,
      status: res.data.status,
      name:res.data.name,
      description:res.data.description,
      city:res.data.city,
      zipcode:res.data.zipcode,
      experience:res.data.experience,
      qualification:res.data.qualification,
      standard_fees:res.data.standard_fees,
      availability_hours1:res.data.availability_hours1,
      availability_hours2:res.data.availability_hours2,
      card_number:res.data.card_number,
      expireMM:res.data.expireMM,
      expireYY:res.data.expireYY,
      cvv:res.data.cvv,
      email:res.data.email,
      licence:res.data.licence,
      phone:res.data.phone,
      smsUpdates:res.data.sms_updates
         });
         if(res.data.otp_verified==0 || res.data.otp_verified==undefined)
          {
          window.location.href = "/professional-phone-otp/"+ru.id; 
          }
          
         // Handle Checkboxes
         var spec = res.data.specialisation;
         var specArray = spec.split(', ');
         specArray.forEach((value) => {
          const checkbox = document.querySelector(`input[value="${value}"]`);
          if (checkbox) {
            checkbox.checked = true;
            //alert(value);
          }

          const checkbox1 = document.querySelector(`input[name="sms_updates"]`);
        if(this.state.smsUpdates=='yes')
        {
          checkbox1.checked = true;

        }
        });
        
      })
      .catch(err =>{
        console.log('Error from professional');
      })
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    const u = jwtDecode(token);
    console.log(u.id);
    
      
  };

  handleFileChange = (e) => {
    
    
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the image in the state
        this.setState({ photo_id1: reader.result });
      };
      reader.readAsDataURL(file);
      this.setState({ photo_id: file });
    }


    
  };
  
  handleCheckboxChange = (event) => {
    const smsUpdates = event.target.checked;
    this.setState({ smsUpdates });
  };


  onChange = e => {
    var office_address = document.getElementById('autocomplete').value;
    this.setState({ [e.target.name]: e.target.value });
    this.setState({office_address:office_address});
    var registered_address = document.getElementById('autocomplete1').value;
    this.setState({ [e.target.name]: e.target.value });
    this.setState({registered_address:registered_address});
  };

  onChange1 = e => {
    this.setState({ [e.target.name]: e.target.value });
  }
  

  onSubmit = e => {
    var office_address = document.getElementById('autocomplete').value;
    var registered_address = document.getElementById('autocomplete1').value;
    const formData = new FormData(e.target);
    const selectedOptions = [];

    // Get all input elements with the name "options"
    const checkboxes = e.target.elements['specialisation'];
    
    // Iterate over the checkboxes
    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i];

      // Check if the checkbox is checked
      if (checkbox.checked) {
        selectedOptions.push(checkbox.value);
      }
    }

    // Display an alert with the selected options
    //alert(`Selected options: ${selectedOptions.join(', ')}`);
    var specialisations = selectedOptions.join(', ');
    //alert('specialisations=>'+specialisations);









    const token = localStorage.getItem("professional-token");
    const u = jwtDecode(token);
    //alert(this.state.card_number);
    e.preventDefault();
    var sms_updates;
    // Iterate over the checkboxes
    if(this.state.smsUpdates=='yes' || this.state.smsUpdates==true)
    {
      sms_updates = 'yes';
    }
    else if(this.state.smsUpdates=='no' || this.state.smsUpdates==false)
    {
      sms_updates='no';
    }
    console.log(this.state.photo_id);
    //alert(this.state.photo_id);
    const data = {
      photo_id: this.state.photo_id,
      registered_address: registered_address,
      office_address: office_address,
      country: "Canada",
      state_or_province: this.state.state_or_province,
      radius_to_cater: this.state.radius_to_cater,
      professional_card: this.state.professional_card,
      status: this.state.status,
      name:this.state.name,
      
      description:this.state.description,
      city:this.state.city,
      zipcode:this.state.zipcode,
      specialisation:specialisations,
      experience:this.state.experience,
      qualification:this.state.qualification,
      standard_fees:this.state.standard_fees,
      availability_hours1:this.state.availability_hours1,
      availability_hours2: this.state.availability_hours2,
      
      user_id:u.id,
      card_number:this.state.card_number,
      expireMM:this.state.expireMM,
      expireYY:this.state.expireYY,
      cvv:this.state.cvv,
      email:this.state.email,
      sms_updates:sms_updates,
      phone:this.state.phone,
      lang:this.state.lang
      
    };

    axios
      .post('/api/professionals/profile', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        
        //alert("Profile updated");
        //window.location.href = "/profile-updated";
        this.setState({submitted:1});
      })
      .catch(err => {
        //alert(err.data.msg);
        console.log("Error in professional-profile!");

      })
      
  };



  handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("professional-token");

    const ru = jwtDecode(token);
    const formData = new FormData(this.formRef.current);
    formData.append('id', ru.id);
    formData.append('phone',this.state.phone);
    formData.append('sms_updates',this.state.smsUpdates);
    formData.append('email',this.state.email);
    formData.append('name',this.state.name);
    formData.append('lang',localStorage.getItem("lang"));
    console.log('test=>'+formData.getAll('fileValues')[0].name);
    if(formData.getAll('fileValues')[0].name!='')
    {
      console.log(formData.getAll('fileValues')[0].name);
      //formData.delete(formData.getAll('fileValues')[0]);
      console.log('ddd');
      //formData.append('fileValues',formData.getAll('fileValues')[0],null);
      // Create a Blob with minimal content (e.g., an empty string)
const emptyContentBlob = new Blob([''], { type: 'text/plain' });

// Create a File object with the Blob
const emptyFile = new File([emptyContentBlob], 'emptyFile.txt', { type: 'text/plain' });

// Append the empty file to FormData
console.log(emptyFile);
//formData.append('fileValues', emptyFile);
  }
    else
    {
      var confirmed = window.confirm("You are Submitting Blank licence values, Are you Sure?");
      if(confirmed==false)
      {
        return;
      }
      
      
    }
    
    
    //console.log(formData.getAll('fileValues')[2].name);
    const response = await fetch('/api/professionals/licenceUpdate', {
      method: 'POST',
      body: formData,
    });
console.log(response);
    if (response.ok) {
      this.setState({submitted:2});
      console.log('Form data saved successfully');
    } else {
      console.error('Error saving form data');
    }
  };


  doNotUpdateLicense = async (e)=>{
    e.preventDefault();
    //alert(this.state.phone+this.state.email+this.state.smsUpdates);
    const data = {'phone':this.state.phone,'sms_updates':this.state.smsUpdates,'email':this.state.email,'name':this.state.name,'lang':localStorage.getItem("lang")};
    
    
    axios
    .post('/api/professionals/withoutlicenceUpdate', data)
    .then(res => {
      
      //alert("Profile updated");
      //window.location.href = "/profile-updated";
      this.setState({submitted:2});
      console.log('Submitted Without licence update');
    })
    .catch(err => {
      //alert(err.data.msg);
      console.log("Error from Licence 2");

    })



      
   
    //this.setState({submitted:2});
    //console.log('Submitted Without licence update');
  }

  handleFileChange = (e) => {

    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the image in the state
        this.setState({ photo_id1: reader.result });
      };
      reader.readAsDataURL(file);
      this.setState({ photo_id: file });
    }



  };


  render() {
    var lang=this.state.lang;
    const listItems = [];
    if(this.state.licence!=undefined && this.state.licence.textValues!=undefined) {
    for (let i = 0; i < this.state.licence.textValues.length; i++) {
      if(i==0)
      {
      listItems.push(<div className="row"><div className="col-md-3"><input  type="text" placeholder={lang=='fr'?("Pour quel service cette licence est-elle valable?"):("Which service is this license for?")} value={this.state.licence.textValues[i]} name="textValues[]" className="form-control" /></div><div className="col-md-3"><input type="file"  name="fileValues" className="form-control" multiple /></div><div className="col-md-2"><button type="button" onClick={this.handleAddFields} className="btn btn-success">Add New</button></div><div className="col-md-2"><button type="button" onClick={this.removeRow} className="btn btn-danger mt-2">Remove</button></div><div className="col-md-2"><img src={'/uploads/'+this.state.licence.fileValues[i]} width="50"/></div></div>);
    }
    else
    {
      listItems.push(<div className="row"><div className="col-md-3"><input  type="text" placeholder={lang=='fr'?("Pour quel service cette licence est-elle valable?"):("Which service is this license for?")} value={this.state.licence.textValues[i]} name="textValues[]" className="form-control" /></div><div className="col-md-3"><input type="file" name="fileValues" className="form-control" multiple /></div><div className="col-md-2"><button type="button" onClick={this.handleAddFields} className="btn btn-success">Add New</button></div><div className="col-md-2"><button type="button" onClick={this.removeRow} className="btn btn-danger mt-2">Remove</button></div><div className="col-md-2"><img src={'/uploads/'+this.state.licence.fileValues[i]} width="50"/></div></div>);
    }
    }
    }
    else
    {
      listItems.push(<div className="row">
      <div className="col-md-3">
      <input type="text" placeholder="Text" name="textValues[]" className="form-control" />
      </div>
      <div className="col-md-5">
      <input type="file" name="fileValues" className="form-control" multiple />
      </div>
      <div className="col-md-2">
        <button type="button" onClick={this.handleAddFields} className="btn btn-success">Add New</button>
      </div>
    </div>);
}

    return (

<div>
      <Headertop/>
	  <div className='container-fluid'>
      <div className='row'>
      
        <Header/>
		<div className='col-md-10 menu-right'>
    { this.state.submitted==0 ?(
      <div className="dashboard-right">
        <h1 className="lang">{lang=='fr'?(<>Modifier votre profil</>):(<>Update Your Profile</>)}</h1>
        <div className='reset-password1'><Link className='btn btn-primary' to='/prof-forgot-password'>{lang=='fr'?(<>Réinitialiser le mot de passe</>):(<>Reset Password</>)}</Link></div>
        <form noValidate onSubmit={this.onSubmit} className="profile form" encType="multipart/form-data">
          <div className="card-box">
            <h4 className="lang">{lang=='fr'?(<>Informations de base</>):(<>Basic Information</>)}</h4>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label lang">{lang=='fr'?(<>Quel est votre nom ?</>):(<>What's Your Name ?</>)}</label>
                <input className="form-control" type="text" name="name" placeholder={lang=='fr'?("Votre nom"):("Your Name")} value={this.state.name} onChange={this.onChange} />
              </div>
              <div className="form-group col-md-6">
                <label className="form-label lang">{lang=='fr'?(<>Téléchargez une photo de profil</>):(<>Upload Profile Picture</>)}</label>
                <div className="row">
                <div className="col-md-10">
                <input className="form-control"  type="file" name="photo_id"   onChange={this.handleFileChange} /></div>
                <div className="col-md-2"><img src={this.state.photo_id1} width="60" height="60"/></div>
                </div>
                
                
              </div>
              
            </div>
            <div className="row">
              
              <div className="form-group col-md-12">
                <label className="form-label lang">{lang=='fr'?(<>Rédigez une courte description de vous-même / votre compagnie. Ceci vous aidera à vous démarquer et vous aidera à connecter avec ceux qui ont besoin de votre expertise !</>):(<>Please write a short description of yourself / your company. This will help you stand out and connect with those seeking your expertise!</>)}</label>
                <textarea placeholder={lang=='fr'?("ex. Bonjour ! Je suis [votre nom], fier.ère propriétaire de [nom de votre compagnie]. Avec [X] années d'expérience dans [votre domaine], je me spécialise en [vos champs d’expertise]. Continuez avec des informations sur vos engagements et vos qualités professionnelles. Détaillez les avantages que les clients expérimenteront en travaillant avec vous pour que votre profil se démarque !"):("ex. Hello! I'm [Your Name], the proud owner of [Your Company Name]. With [X] years of experience in [Your Industry], I specialize in [Your Areas of Expertise]. Continue with information on your commitments and professional qualities. Explain the advantages the clients will experience when working with you to make your profile stand out!")} className="form-control" name="description"  value={this.state.description} onChange={this.onChange}>{this.state.description}</textarea>
              </div>
            </div>
          </div>
          <div className="card-box">
            <h4 className="lang">{lang=='fr'?(<>Adresse</>):(<>Address</>)}</h4>

            <div className="row">
              <div className="col-md-12 form-group">
                <label className="form-label lang">{lang=='fr'?(<>Adresse</>):(<>Registered Address</>)}<div className="tooltip-container"><span className="question-mark">?</span><div className="tooltip">{lang=='fr'?(<>Pour la facturation</>):(<>This address is used for billing purposes.</>)}</div></div></label>
                <input id="autocomplete1" className="form-control" type="text" name="registered_address"  value={this.state.registered_address} onChange={this.onChange} required="required" />
            </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label className="form-label lang">{lang=='fr'?(<>Adresse de base </>):(<>Home Base Address </>)}<div className="tooltip-container"><span className="question-mark">?</span><div className="tooltip">{lang=='fr'?(<>Pour les calculs de la zone de couverture</>):(<>​This is the address which will be used for coverage area calculations.</>)}</div></div></label>
                <input id="autocomplete" className="form-control" type="text" name="office_address"  value={this.state.office_address} onChange={this.onChange} />
              
              
              </div>
            </div>
            {/*<div className="row">
              <div className="col-md-6 form-group">
                <label className="form-label lang">Country</label>
                <select className="form-select" name="country" value={this.state.country} onChange={this.onChange} >
                  <option value="Canada">Canada</option>
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label lang">Province</label>
                <select className="form-select" name="state_or_province" value={this.state.state_or_province} onChange={this.onChange} >
                <option value="Alberta">Alberta</option>
	<option value="British Columbia">British Columbia</option>
	<option value="Manitoba">Manitoba</option>
	<option value="New Brunswick">New Brunswick</option>
	<option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
	<option value="Northwest Territories">Northwest Territories</option>
	<option value="Nova Scotia">Nova Scotia</option>
	<option value="Nunavut">Nunavut</option>
	<option value="Ontario">Ontario</option>
	<option value="Prince Edward Island">Prince Edward Island</option>
	<option value="Quebec">Quebec</option>
	<option value="Saskatchewan">Saskatchewan</option>
	<option value="Yukon">Yukon</option>
                </select>
              </div>
</div> */}
            <div className="row">
              {/*<div className="col-md-4 form-group">
                <label className="form-label lang">City</label>
                <input className="form-control" type="text" name="city"  value={this.state.city} onChange={this.onChange} />
              </div>
              <div className="col-md-4 form-group">
                <label className="form-label lang">Postal Code</label>
                <input className="form-control" type="text" name="zipcode"  value={this.state.zipcode} onChange={this.onChange} />
              </div>
*/}
              <div className="col-md-6 form-group">
              
                <label className="form-label lang">{lang=='fr'?(<>Disponible du</>):(<>Available From</>)}</label>
                <input value={this.state.availability_hours1} className="form-control" type="time" name="availability_hours1" onChange={this.onChange} />
                </div>
                <div className="col-md-6 form-group">
                <label className="form-label lang">{lang=='fr'?(<>au</>):(<>To</>)}</label>
                <input value={this.state.availability_hours2} className="form-control" type="time" name="availability_hours2" onChange={this.onChange} />
              </div>
              
</div> 
            
          </div>
          <div className="card-box">
            <h4 className="lang">{lang=='fr'?(<>votre zone de couverture</>):(<>Your Coverage Area</>)}</h4>
            <div className="col-md-12 form-group">
                <label className="form-label lang">{lang=='fr'?(<>Quelle est la distance maximale que vous êtes prêt à parcourir à partir de l'adresse de base que vous avez indiquée ci-dessus ?<br/>Sélectionnez un rayon pour déterminer votre zone de couverture : Veuillez sélectionner le rayon KM</>):(<>What’s the maximum distance you’re willing to travel away from the ‘’Home Base Address’’ you provided above?<br/>Select a radius to determine your coverage area:  Please select radius in kilometers KM</>)}</label>
                <input className="form-control" type="text" name="radius_to_cater" placeholder="200" value={this.state.radius_to_cater} onChange={this.onChange} />
              </div>
          </div>
          <div className="card-box">
            <h4 className="lang">{lang=='fr'?(<>Informations professionnelles</>):(<>Professional Information</>)}</h4>

            <div className="row form-group">

              <label className="form-label col-md-12 lang">{lang=='fr'?(<>Choisissez votre spécialisation</>):(<>Select your area(s) of expertise*</>)}</label>

              <div className="form-check col-md-4">
                <div className='check-box'>
                  <input  type="checkbox"  name="specialisation" className="form-check-input" value="Plumbing Services"  /><label className="form-check-label" >{lang=='fr'?(<>Services de plomberie</>):(<>Plumbing Services</>)}</label>
                </div>
              </div>
              <div className="form-check col-md-4">
                <div className='check-box'>
                  <input type="checkbox"  name="specialisation" className="form-check-input" value="Carpenter Services"  /><label className="form-check-label" >{lang=='fr'?(<>Services de menuiserie</>):(<>Carpenter Services</>)}</label>
                </div>
              </div>

              <div className="form-check col-md-4">
                <div className='check-box'>
                  <input type="checkbox"  name="specialisation" className="form-check-input" value="Pest Control Services" /><label className="form-check-label" >{lang=='fr'?(<>Services de lutte contre les parasites</>):(<>Pest Control Services</>)}</label>
                </div>
              </div>
              <div className="form-check col-md-4">
                <div className='check-box'>
                  <input type="checkbox"   name="specialisation" className="form-check-input" value="Roofing Services"  /><label className="form-check-label" >{lang=='fr'?(<>Services de couverture</>):(<>Roofing Services</>)}</label>
                </div>
              </div>
              <div className="form-check col-md-4">
                <div className='check-box'>
                  <input type="checkbox"   name="specialisation" className="form-check-input" value="AC Repair Services" /><label className="form-check-label" >{lang=='fr'?(<>Services de réparation de l'air conditionné</>):(<>AC Repair Services</>)}</label>
                </div>
              </div>
              <div className="form-check col-md-4">
                <div className='check-box'>
                  <input type="checkbox"   name="specialisation" className="form-check-input" value="Electrician Services"/><label className="form-check-label" >{lang=='fr'?(<>Services d'électriciens</>):(<>Electrician Services</>)}</label>
                </div>
              </div>
              <div className="form-check col-md-4">
                <div className='check-box'>
                  <input type="checkbox"   name="specialisation" className="form-check-input" value="Cleaning and Disinfection Services"/><label className="form-check-label" >{lang=='fr'?(<>Nettoyage et désinfection</>):(<>Cleaning & Disinfection</>)}</label>
                </div>
              </div>
              <div className="form-check col-md-4">
                <div className='check-box'>
                  <input type="checkbox"   name="specialisation" className="form-check-input" value="Electrical Appliance Repair" /><label className="form-check-label" >{lang=='fr'?(<>Réparation d'appareils électriques</>):(<>Electrical Appliance Repair</>)}</label>
                </div>
              </div>
             <p>*IMPORTANT: Make sure you have a valid license to provide for all the services you selected. Without the appropriate documentation, SOS BLAST will not be able to advertise your expertise on its platform.</p>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label className="form-label lang">{lang=='fr'?(<>Expérience</>):(<>Experience</>)}</label>
                <select className="form-select" name="experience" value={this.state.experience} onChange={this.onChange} >
                  <option value="">Choose Experience (Years of Experience)</option>
                  <option value="5">5</option>
                  <option value="5-10">5-10</option>
                  <option value="10-15">10-15</option>
                  <option value="15-20">15-20</option>
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label lang">Qualification</label>
                <input className="form-control" type="text" name="qualification"  value={this.state.qualification} onChange={this.onChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="form-label lang">{lang=='fr'?(<>Ajoutez un montant minimal</>):(<>Choose a minimum fee (in CAD)</>)}<div className="tooltip-container"><span className="question-mark">?</span><div className="tooltip">{lang=='fr'?(<>Vous pouvez exiger un montant servant à garantir que la demande de service est sérieuse. Comparable au montant préautorisé dans une station-service, ce tarif minimum établit un certain niveau d’engagement de la part des deux parties.</>):(<>You can request a fee that serves as a commitment marker, ensuring that the service request is serious. Comparable to securing a service at a gas station, this minimum fee establishes a level of seriousness from both parties.</>)}</div></div></label>
                <input className="form-control" type="text" name="standard_fees" placeholder="$30.00" value={this.state.standard_fees} onChange={this.onChange} />
              </div>
              
            
              
              </div>
              
          </div>
          <div className="card-box">
            <h4 className="lang">{lang=='fr'?(<>Informations de paiement</>):(<>Payment Information</>)} </h4>

            <div className="row">
              <div className="col-md-12 form-group">
                <label className="form-label lang">{lang=='fr'?(<>Numéro de carte</>):(<>Card Number</>)}</label>
                <input className="form-control" type="text" name="card_number" placeholder={lang=='fr'?("Entrez votre numéro de carte"):("Type Card Number")} value={this.state.card_number} onChange={this.onChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="form-label lang">{lang=='fr'?(<>Expiration le</>):(<>Expiry on</>)}</label>
                <div className="row">
                  <div className="col-md-4">
                <select className="form-select" name='expireMM' id='expireMM' value={this.state.expireMM} onChange={this.onChange}>
    <option value=''>Month</option>
    <option value='01'>January</option>
    <option value='02'>February</option>
    <option value='03'>March</option>
    <option value='04'>April</option>
    <option value='05'>May</option>
    <option value='06'>June</option>
    <option value='07'>July</option>
    <option value='08'>August</option>
    <option value='09'>September</option>
    <option value='10'>October</option>
    <option value='11'>November</option>
    <option value='12'>December</option>
</select>
</div>
<div className="col-md-4">
<select className="form-select" name='expireYY' id='expireYY' value={this.state.expireYY} onChange={this.onChange}>
    
    <option value='24'>2024</option>
    <option value='25'>2025</option>
    <option value='26'>2026</option>
    <option value='27'>2027</option>
    <option value='28'>2028</option>
    <option value='29'>2029</option>
    <option value='30'>2030</option>
    <option value='31'>2031</option>
    <option value='32'>2032</option>
    <option value='33'>2033</option>
    <option value='34'>2034</option>
    <option value='35'>2035</option>
</select>
</div>              
</div>              
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label lang">CVV</label>
                <input  className="form-control" type="text" maxLength="3" name="cvv" placeholder={lang=='fr'?("Entrez le CVV"):("Type CVV")} value={this.state.cvv} onChange={this.onChange} />
              </div>
              
            </div>
            <div className='row'><div className="col-md-12 form-group sms_updates">
              <label className="form-label lang ">{lang=='fr'?(<>Souhaitez-vous recevoir des notifications par SMS ?</>):(<>Do you wish to receive SMS updates?</>)}</label><br/>
<span>{lang=='fr'?(<>Non</>):(<>No</>)}</span><label className="switch"><input checked={this.state.isChecked} onChange={this.handleCheckboxChange} name="sms_updates" type="checkbox" /><span className="slider round"></span></label><span>{lang=='fr'?(<>Oui</>):(<>Yes</>)}</span>
              </div></div>
            <input type="hidden" name='status' value={this.state.status} />
              <input type="hidden" name='user_id' value={this.state.user_id} />
              <input type="hidden" name='email' value={this.state.email} />
              <input type="hidden" name='phone' value={this.state.phone} />
            <input className="form-control button-nav" name="submit" value={lang=='fr'?("Sauvegarder"):("Save Changes")} type="submit"  />
          </div>
        </form>
        </div>
        ): this.state.submitted==1 ?( 
        <div className="dashboard-right"><br/>
        <p className="lang">{lang=='fr'?(<>*IMPORTANT : Assurez-vous que vous détenez une licence valide pour tous les services sélectionnés. Sans la documentation appropriée, SOS BLAST ne sera pas en mesure de promouvoir votre expertise sur sa plateforme.</>):(<>*IMPORTANT: Make sure you have a valid license to provide for all the services you selected. Without the appropriate documentation, SOS BLAST will not be able to advertise your expertise on its platform.</>)}</p><br/>
        <p>{lang=='fr'?(<>Mettez à jour votre licence</>):(<>Update Your Licences</>)}</p>
        <form ref={this.formRef} onSubmit={this.handleSubmit} encType='multipart/form-data'>
        {listItems}
        <div className='row licence_buttons'><div className='col-md-4' id="update_licence"><button type="submit" className="btn btn-success mt-3">Continue With Licence Update</button></div><div className="col-md-2"></div><div className='col-md-4' id="submit_licence"><button onClick={this.doNotUpdateLicense} type="submit" className="btn btn-primary mt-3">Continue Without Licence Update</button></div></div>
        </form> 
        </div>
        ):
        (
          <div className="dashboard-right thanks_msg">
          <div className="content-left">
          <h3 className="lang">Profile Successfully Updated!</h3>
          <h5 className="lang">We wanted to let you know that your profile on SOS BLAST has been successfully updated. Your enhanced profile will help you stand out and connect with individuals and businesses seeking your expertise.</h5>
          </div>
          </div> 
        )}

      </div>
</div>
</div>
</div>



    );
  }
}

export default UpdateProfessionalProfile;