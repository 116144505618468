import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Headermain from './Headermain';

class ProfResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      
      password:''
    };
  }
  componentDidMount() {
    const params = this.props.params;
    console.log(params.id,params.token);
    axios
      .get('/api/professionals//get-professional1/'+params.id+'/'+params.token)
      .then(res => {
        console.log('dddddd');
        if(res.data.msg=="false")
        {
        window.location.href = "/prof-forgot-password?msg=link-expired"; 
        }
      })
      .catch(err => {
        //alert(err.data.msg);
        alert('error');
        console.log("Error in CreateBook!");
      })   
  };
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const params = this.props.params;
    const data = {
      id:params.id,
      password:this.state.password
      
    };

    axios
      .post('/api/professionals/reset-password', data)
      .then(res => {
        this.setState({
          
          password:''
          
        });
        alert(res.data.msg);
        window.location.href = "/login-as-professional"; 
      })
      .catch(err => {
        alert(err.data.msg);
        console.log("Error in CreateBook!");
      })
  };
  render() {
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
    return (
	  <div>
      <Headermain/>
      <div className='col-md-12 banner-header'><h1>Lorem Ipsum</h1></div>
      <div className="middle-section">
        <div className="middle">
        <div className="row">
          <div className="col-md-5 left_section">
            </div>
          <div className="col-md-7 right_section reset-password">
          <div className="content-right ">
          <h3>Reset your Password</h3>
<div className="form-register"><p>Enter New Password</p>
<form noValidate onSubmit={this.onSubmit}>

<input type="password" name="password" placeholder="New Password" value={this.state.password} onChange={this.onChange}/>
<input type="password" name="c_password" placeholder="Confirm New Password" value={this.state.c_password} onChange={this.onChange}/>
<input name="submit" value="Reset Password" type="submit" className="button-nav"/>
<p><Link to='/'>Not registered? Sign up</Link></p>
</form>
</div>
            </div>
          </div>
        </div>
        </div>
      </div>
	  </div>
    );
  }
}

export default () => {
    const params = useParams();
    //console.log(params);
  return (
      <ProfResetPassword params={params} />
  )
  }