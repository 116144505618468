import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Link, useParams } from 'react-router-dom';


import Header from './Headeradmin';
import Headertop from './Headeradmintop';

class AdminViewUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqqs: []
    };
  }

  componentDidMount() {
    const params = this.props.params;
    const token = localStorage.getItem("admin_token");
    if(!token)
    {
      window.location.href = "/login";
    }
    const u = jwtDecode(token);
    console.log(u.id);
    
    axios
      .get('/api/users/get-user/'+params.id)
      .then(res => {
        this.setState({
          reqqs: res.data
         })
         
      })
      .catch(err =>{
        console.log('Error from Profile');
      })
      
  };
  

  render() {
    const reqqs = this.state.reqqs;
    //console.log("PrintBook: " + JSON.stringify(reqqs));
    

    return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
      <Header />
        <div className='col-md-10 menu-right'>
      
        <div className="dashboard-right ">
        <h2 className="page-title lang">User Profile</h2>
    <div className="dashboard-cart">
        
<div className="row">
    <div className='col-md-12'><h4><img src="/img/profile-img.jpg" width="80"/><span className="lang">{reqqs.name}</span></h4></div>
  <div className="col-md-4">
    <h4 className="lang">About User</h4>
    <p className="lang">Name: {reqqs.name}</p>
    <p className="lang">Phone: {reqqs.phone}</p>
    </div>
    <div className='col-md-4'></div>
    <div className='col-md-4'>
    <h4 className="lang">User Information</h4>
    <p><strong className="lang">Email: </strong><span className="lang">{reqqs.email}</span></p>
    <p><strong className="lang">Phone: </strong><span className="lang">{reqqs.phone}</span></p>
    <p><strong className="lang">Preferred Language: </strong><span className="lang">{reqqs.language}</span></p>
    </div>
    
      
  </div>
        
    </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
    );
  }
}


//export default UserViewProfessionalProfile;

export default () => {
    const params = useParams();
    //console.log(params);
  return (
      <AdminViewUserProfile params={params} />
  )
}