import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Header from './Headeruser';
import Headertop from './Headeradmintop';
import { Link } from 'react-router-dom';
import '../toggle.css';


class UserAccountSetting extends Component {
  constructor() {
    super();
    this.state = {
      email:'',
      name:'',
      language:'en',
      smsUpdates:false

        
    }
    
    
  }

  
  componentDidMount() {
    
    const token = localStorage.getItem("token");
    if(!token)
    {
      window.location.href = "/login";
    }
    const ru = jwtDecode(token);
    
    console.log('test=>'+ru.iat);
    const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
    if(exp_time>3600)
    {
      window.location.href = "/user-logout";
    }

    axios.get('/api/users/get-user/'+ru.id).then(res => {
        this.setState({
          email:res.data.email,
      name:res.data.name,
      language:res.data.language,
      smsUpdates:res.data.sms_updates
         });
         if(res.data.otp_verified==0 || res.data.otp_verified==undefined)
          {
          window.location.href = "/phone-otp/"+ru.id; 
          }
         // Handle Checkboxes
         
        //alert(this.state.smsUpdates);
        const checkbox = document.querySelector(`input[name="sms_updates"]`);
        if(this.state.smsUpdates=='yes')
        {
          checkbox.checked = true;

        }
        
      })
      .catch(err =>{
        console.log('Error from professional');
      })
    
    
    
      
  };

  
  handleCheckboxChange = (event) => {
    const smsUpdates = event.target.checked;
    this.setState({ smsUpdates });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  

  onSubmit = e => {
    
    //alert(this.state.smsUpdates);
    //console.log('ddd=>'+this.state.smsUpdates);
    var sms_updates;
    // Iterate over the checkboxes
    if(this.state.smsUpdates=='yes' || this.state.smsUpdates==true)
    {
      sms_updates = 'yes';
    }
    else if(this.state.smsUpdates=='no' || this.state.smsUpdates==false)
    {
      sms_updates='no';
    }
    const token = localStorage.getItem("token");
    const u = jwtDecode(token);
    e.preventDefault();
    
    const data = {
      email:this.state.email,
      name: this.state.name,
      language:this.state.language,
      sms_updates:sms_updates,
      id:u.id
    };

    axios
      .post('/api/users/update-account', data)
      .then(res => {
        
        if(res.status==200)
        {
        //alert(res.data.msg);
        window.location.href = "/user-profile-updated";
        }
        else
        {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        //alert(err.data.msg);
        console.log("Error in professional-profile!");

      })
      
  };



  render() {
    

    return (

<div>
      <Headertop/>
	  <div className='container-fluid'>
      <div className='row'>
      
        <Header/>
		<div className='col-md-10 menu-right'>
      <div className="dashboard-right">
        <h1 className="lang">Edit Your Profile</h1>

        <form noValidate onSubmit={this.onSubmit} className="profile form" encType="multipart/form-data">
          
          <div className="card-box">
            <h4 className="lang">Address</h4>
            
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="form-label lang">Email</label>
                <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.onChange} />
                  
                
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label lang">Name</label>
                <input type="text" className="form-control" name="name" value={this.state.name} onChange={this.onChange} />
                  
                
              </div>
              <div className="col-md-12 form-group">
                <label className="form-label lang">Prefered Language</label>
                <select className="form-control" name="language" value={this.state.language} onChange={this.onChange} >
                  <option value="">Choose Language</option>
                  <option value="eng">English</option>
                  <option value="fr">French</option>
                  
                </select>
              </div>
              <div className="col-md-12 form-group">
              <label className="form-label lang">Sms Updates?</label><br/>
<span>No</span><label className="switch"><input checked={this.state.isChecked} onChange={this.handleCheckboxChange} name="sms_updates" type="checkbox" /><span className="slider round"></span></label><span>Yes</span>
              </div>
              
</div>
<div className="row">
              <div className="col-md-3 form-group">
              <input className="form-control button-nav" name="submit" value="Update Profile" type="submit"  />
              </div>
              <div className='col-md-6'></div>
              <div className='col-md-3'><Link className="btn btn-primary" to="/forgot-password">Reset Password</Link>
            </div>
            </div>
            
            
          </div>
          
        </form>

      </div>
</div>
</div>
</div>
</div>


    );
  }
}

export default UserAccountSetting;