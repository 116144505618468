import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import axios from 'axios';


const UserReqCardPendingApplied = (props) => {
    const  reqq  = props.reqq;
    //console.log(reqq._id);
    const fetchData = () => {
        const  reqq1  = props.reqq;
        // Perform the Axios request here
        console.log(reqq1);
        axios.get('/api/requests/mark-completed/'+reqq1._id)
          .then((response) => {
            console.log(response.data); // Update the state with the response data

            alert(response.data.msg)
            window.location.href = "/user-completed-requests";
          })
          .catch((error) => {
            console.error(error);
          });
      };


    

    return(
        <div className="card-container col-md-12">
           <div className="desc list_container">
            <div className="list_left">
                <h1 className="list_title">
                    <Link className="lang" to={`/user-view-request/${reqq._id}`}>
                        { reqq.repair_explanation }
                    </Link>
                    
                </h1>
                <p className="lang">{ reqq.address_1 } </p>
                </div>
                <div className="list_right">
                <Link to={`/user-view-request/${reqq._id}`} className="btn btn-info btn-right language btn-size">
                        View
                    </Link>
                    <button className="btn btn-info btn-right btn-right1 language btn-size" onClick={fetchData}>Mark Completed</button>
                </div>
            </div>

        </div>
    )
};

export default UserReqCardPendingApplied;