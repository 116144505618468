import React, { Component } from 'react';
import '../../App.css';
import '../../Style.css';
import '../../script.js';
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from './inc/Footer';

class Phome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homes: [],
      services:[]
    };
  }



  componentDidMount() {
    if(localStorage.getItem("lang"))
    {
      if(localStorage.getItem("lang")=='en')
      {
       //alert(localStorage.getItem("lang"));
       window.location.href = "/professional";
      }
    }
    axios
      .get('/api/fr/pages/professional-page')
      .then(res => {
        console.log("Print-showapplicationDetails-API-response: " + JSON.stringify(res.data));
        this.setState({
          homes: res.data
        })
      
         
      })
      .catch(err => {
        console.log("Error from viewAppDetails");
      })

      axios
        .get('/api/fr/services')
        .then(res => {
          console.log("Print-services-API-response: " + JSON.stringify(res.data));
          this.setState({
            services: res.data
          })
        })
        .catch(err => {
          console.log("Error from viewAppDetails");
        })
  };
  

  render() {
    
const homes = this.state.homes;
const services = this.state.services;
console.log('this=>'+homes);
var data = homes.section_1_description;
var data1 = homes.banner_bottom_heading;
var data2 = homes.services_description;
if(data!==undefined)
{
console.log(data);
//alert(data);
const decodedData = data.replace(/\n/g, '<br>');
// Display 'decodedData' in your HTML using innerHTML
document.getElementById('content_description').innerHTML = decodedData;
document.getElementById('banner_bottom_heading').innerHTML = data1.replace(/\n/g, '<br>');
document.getElementById('services_description').innerHTML = data2.replace(/\n/g, '<br>');
}
    return (
      <>
      
<div className="top-banner top-banner-professional">
<div className="container">
<div className="row">
<div className="col-md-12">
<nav className="navbar navbar-expand-lg navbar-light  navbar-fixed-top">
  <div className="container-fluid">
    <a className="navbar-brand" href="#"><img src="/img/logo-final.png" width="90"/></a>
    
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        
      </ul>
      <ul className="nav navbar-nav navbar-right">
      <li className="nav-item">
          <Link className="nav-link" to="/login">se connecter</Link>
        </li>
      <li className="nav-item">
          <Link className="nav-link lang" aria-current="page" to="/fr/professional">Inscription professionnelle</Link>
        </li>
        <li className="nav-item"><Link  to="/login-as-professional" className="nav-link lang">Connexion professionnelle</Link></li>

        <li className="nav-item">
          <button className="en lan" data-id="en"><img src='/en.png'/></button>
          <button className="fr lan" data-id="fr"><img src='/fr.png'/></button>
          </li>
          </ul>
    </div>
  </div>
</nav>
	
	
</div>

	</div>
</div>
<div className="center-div">
<h1><span>{homes.banner_h1}</span></h1>
<h3>
        <span>{homes.banner_h3}</span>
      </h3>
<a href={homes.banner_link} className="btn btn-info btn-prof">Get Started</a>
</div>
</div>
<div className="banner-bottom banner-bottom-prof">
<div className="container">
<div className="row">
<div className="col-md-12">
<h2 id="banner_bottom_heading">{homes.banner_bottom_heading}</h2>
{/* <a href={homes.banner_bottom_link} className="btn btn-info btn-prof">Register Now</a> */}
</div>
</div>
</div>	
</div>
{/*<div className="how_we_work">
<div className="container">
<h2>
        <span>{homes.how_heading}</span>
      </h2>
<h5>
        <span>{homes.how_description}</span>
      </h5>

</div>	
</div>
*/}
<div className="mid-section  mid-section1">
<div className="container">
<div className="row">
<div className="col-md-12"><h3>{homes.section_1_heading}</h3></div>
<div className="col-md-12">
<div className="content">
<p id="content_description">

</p>
{/* <a href={homes.section_1_link} className="btn btn-info btn-prof">Read More</a> */}
</div>	
</div>
{/* <div className="col-md-6">
<div className="img">
<img src="/img/mid-section.png"/>	
</div>
</div>	 */}
</div>
</div>	
</div>


<div className="services customer_satisfaction customer_satisfaction1">
<div className="container">
<h2>Comment débuter ?</h2>
<div className="row">
<div className="odd1 col-md-2">01</div><div className="odd2 col-md-10"><h5>Inscrivez-vous:</h5>Créez un compte sur SOS BLAST et complétez votre profil pour mettre en avant votre expertise et vos qualifications.</div>
<div className="even2 col-md-10"><h5>Définissez vos préférences:</h5>Spécifiez les types d'urgences que vous êtes en mesure de gérer et les régions que vous pouvez desservir.</div><div className="even1 col-md-2">02</div>
<div className="odd1 col-md-2">03</div><div className="odd2 col-md-10"><h5>Vérification d'éligibilité:</h5>Soumettez les documents de vérification et d'antécédents (si applicable). Cela nous permet de rendre notre plateforme sécurisée pour tous les utilisateurs.</div>
<div className="even2 col-md-10"><h5>Recevez des alertes:</h5>Une fois votre inscription approuvée, vous recevrez des alertes en temps réel concernant les urgences dans votre domaine et votre région.</div><div className="even1 col-md-2">04</div>
<div className="odd1 col-md-2">05</div><div className="odd2 col-md-10"><h5>Échangez avec les clients:</h5>Répondez rapidement aux demandes des utilisateurs en fournissant des devis et les délais estimés.</div>


<div className="even2 col-md-10"><h5>Offrez un service de qualité:</h5>Une fois choisi.e par un client.e, fournissez le service exceptionnel pour lequel vous êtes reconnu et aidez-les à retrouver leur sérénité.</div><div className="even1 col-md-2">06</div>
</div>
<div className="row">
<div className="col-md-4">
<div className="service customer customer1">
<img src="/img/consumer1.png"/>
<h1>300+</h1>
<p>Verfied Professionals</p>
</div>
</div>
<div className="col-md-4">
<div className="service customer customer1">
<img src="/img/consumer2.png"/>
<h1>3500</h1>
<p>Job Successfully Completed</p>
</div>
</div>
<div className="col-md-4">
<div className="service customer customer1">
<img src="/img/consumer3.png"/>
<h1>98%</h1>
<p>Customer<br/>Satisfaction</p>
</div>
</div>
</div>
</div>
</div>
<div className="services services1">
<div className="container">
<h2>{homes.services_heading}</h2>
<h5 id="services_description">{homes.services_description}</h5>
<div className="row">
{services.map(service => {
          return <div className="col-md-3">
          <div className="service service1">
            <img src={`/img/${service.icon}`}/>
            <h6>{service.name}</h6>
            </div></div>
        })}





</div>
</div>
</div>
<Footer/>

      </>
        
        
    );
  }
}

export default Phome;