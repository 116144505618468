import React, { Component } from 'react';
import '../App.css';
import axios, { Axios } from 'axios';
import { Link, useParams } from 'react-router-dom';
import Headermain from './Headermain';
import jwtDecode from 'jwt-decode';

class ProfessionalOtpVerify extends Component {
  constructor() {
    super();
    this.state = {
      phone:'',
      lang:'en'
    };
  }

  componentDidMount() {
    var lan = localStorage.getItem("lang");
  
    if(lan=='fr')
    {
      this.setState({lang: 'fr'});
    }
    else
    {
      this.setState({lang: 'en'});
    }
    const params = this.props.params;
    axios
      .get('/api/professionals/get-professionall/'+params.id)
      .then(res => {
        //alert(res.data.phone);
        this.setState({phone:res.data.phone});
        console.log(res.data.otp_verified);
        //alert(res.data.phone);
        if(res.data.otp_verified=="1")
        {
        window.location.href = "/update-professional-profile"; 
        }
      })
      .catch(err => {
        //alert(err.data.msg);
        alert('error');
        console.log("Error in CreateBook!");
      })   
  
  
}

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    //alert(this.state.phone);
    //exit();
    const params = this.props.params;
    const data = {
      otp: this.state.otp,
      phoneNumber:this.state.phone,
      id : params.id
    };

    //alert(data);
   // alert(JSON.stringify(data));
    axios
      .post('/api/sms/professional-verify-otp', data)
      .then(res => {
        this.setState({
          otp: ''
        });
        
        //alert(res.data.msg);
        
        
        if(res.status==200)
        {
        window.location.href = "/professional-thanks-signup";
        }
        else if(res.status==400)
{
  alert(res.data.msg);
}
else if (res.status==201)
{
  alert(res.data.msg);
}

      })
      .catch(err => {
        alert(err);
        //alert(err.msg)
        console.log("Error in CreateBook!");
      })
  };



  handleResendClick = async () => {
    const params = this.props.params;
    const data = {
      phoneNumber: this.state.phone,
      id:params.id
    };

    axios
      .post('/api/sms/prof-resend-otp',data)
      .then(res => {
        this.setState({
          otp: ''
        });
        alert("OTP Resent");
         
      })
      .catch(err => {
        //alert(err.data.msg);
        alert('error');
        console.log("Error in CreateBook!");
      })
    
  };

  render() {
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/

var lang=this.state.lang;
    return (
      <div>
      <Headermain/>
      
      <div className='col-md-12 banner-header'><h1 className="lang">{lang=='fr'?(<>Code de vérification</>):(<>Verification Code</>)}</h1></div>
      <div className="middle-section">
        <div className="middle">
        <div className="row">
          <div className="col-md-5 left_section">
            <div className="content-left">
<h3 className='title lang'>{lang=='fr'?(<>Vérifiez votre téléphone !</>):(<>Check your phone !</>)}</h3>

          </div></div>
          <div className="col-md-7 right_section">
          <div className="content-right">
          {/* <h3 className='title_right lang'>Please enter the 4-digit code you received</h3> */}

<div className="form-register"><p className="lang">{lang=='fr'?(<>Entrez le code à 4 chiffres que vous avez reçu :</>):(<>Please enter the 4-digit code you received :</>)} </p>
<form noValidate onSubmit={this.onSubmit}>
<div id="divOuter">
    <div id="divInner">
    <input id="partitioned"  type="text" pattern="[1-9][0-9]{3}" maxLength="4" name="otp" value={this.state.otp} onChange={this.onChange} />
        <input name="submit" value={lang=='fr'?("Continuer"):("Continue")} type="submit" className="button-nav"/>
    </div>
</div>

</form>
<span className="resend1">{lang=='fr'?(<>Envoyer le code à nouveau</>):(<>Send code again</>)}</span>
<button className="resend" onClick={this.handleResendClick}>{lang=='fr'?(<>Envoyer le code à nouveau</>):(<>Send code again</>)}</button>
</div>

            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    );
  }
}


export default () => {
  const params = useParams();
  //console.log(params);
return (
    <ProfessionalOtpVerify params={params} />
)
}