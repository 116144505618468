import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import Headertop from './Headeradmintop';
import Footer from './Footer';
import Headeruser from './Headeruser';
import jwtDecode from 'jwt-decode';

class UserDashboard extends Component {
  
  constructor() {
    
    //console.log(id);
    
    super();
    this.state = {
      reqqs:'',
      reqqs1:''
    };
    
  }

  componentDidMount() {
    
    const token = localStorage.getItem("token");
    if(!token)
    {
      window.location.href = "/user-logout";
      
    }
    const u = jwtDecode(token);
    console.log((Math.floor(Date.now()/1000))-u.iat);
    const exp_time = (Math.floor(Date.now()/1000))-u.iat;
    if(exp_time>3600)
    {
      window.location.href = "/user-logout";
    }
    console.log(u.id);
    
      axios
        .get('/api/users/get-user/'+u.id)
        .then(res => {
          
          console.log(res.data.otp_verified);
          if(res.data.otp_verified=="0" || res.data.otp_verified==undefined)
          {
          window.location.href = "/phone-otp/"+u.id; 
          }
        })
        .catch(err => {
          //alert(err.data.msg);
          alert('error');
          console.log("Error in CreateBook!");
        })   
    
    
    
        var data1 = {id:u.id};

        //alert(localStorage.getItem("p_specialisation"));
            axios
              .post('/api/users/user-dashboard-data/', data1)
              .then(res => {
                this.setState({
                  reqqs1: res.data
                 })
                 console.log(res.data);
              })
              .catch(err =>{
                console.log('Error from ShowBookList');
              })
        


              axios
              .get('/api/requests/pending-requests/'+u.id)
              .then(res => {
                this.setState({
                  reqqs: res.data
                 });
                 console.log(res.data);
              })
              .catch(err =>{
                console.log('Error from ShowBookList');
              })

  };

  

  



  

  render() {
    const reqqs = this.state.reqqs;
    console.log(reqqs);
    var itemElements;
    if(!reqqs) {
      itemElements = "there is no requests record!";
    }
    else
    {
     itemElements = reqqs.map((item) => (
      <div key={item._id}>
       <li><Link to={"/user-view-request/"+item._id}><h6>{item.repair_explanation}</h6><p>{item.specialisation}</p></Link></li>

      </div>
    ));
     }
    return (
<div>
      <Headertop/>
      <div className='container-fluid'>
      <div className='row'>
      <Headeruser />
<div className="col-md-10 dashboard">
<div className="row">
<div className="col-md-3 "><div className=" dash_box dash_box1"><span><img src="./img/img1.png" width="50"/></span><span><h2>{this.state.reqqs1.job_posted}</h2><p>Total Job Posted</p></span><span className="arrow"><img src="./img/arrow-right.png" width="30"/></span></div></div>
<div className="col-md-3"><div className="dash_box dash_box2"><span><img src="./img/img2.png" width="50"/></span><span><h2>{this.state.reqqs1.appl_received}</h2><p>Application Received</p></span><span className="arrow"><img src="./img/arrow-right.png" width="30"/></span></div></div>
<div className="col-md-3"><div className="dash_box dash_box3"><span><img src="./img/img3.png" width="50"/></span><span><h2>{this.state.reqqs1.pr_shortlisted}</h2><p>Professionals<br/>Shortlisted</p></span><span className="arrow"><img src="./img/arrow-right.png" width="30"/></span></div></div>
<div className="col-md-3"><div className="dash_box dash_box4"><span><img src="./img/img4.png" width="50"/></span><span><h2>{this.state.reqqs1.job_completed}</h2><p>Job Completed</p></span><span className="arrow"><img src="./img/arrow-right.png" width="30"/></span></div></div> 
</div> 
<div className="row">
<div className="col-md-12"><div className="card-box notification_list"><h5>Pending Requests</h5><ul>
{itemElements}
  </ul></div></div>
{/* <div className="col-md-4"><div className="card-box"><div id="graph"></div></div></div>   */}
</div> 
</div>
        </div>
        </div>
        <Footer/>
</div>

      


    );
  }

  
}

export default () => {
    const params = useParams();
    //console.log(params);
  return (
      <UserDashboard params={params} />
  )
}

//export default ProfessionalApplyRequestForm;