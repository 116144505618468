import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import axios from 'axios';




function deleteUser(event)
{
    var deleteUser = window.confirm('Do you want to delete this user?');
    if(deleteUser===true)
    {
        //alert('yes');
        var id = event.target.getAttribute('data-id');

        //alert(id);

        axios
        .get('/api/users/delete-user/'+id)
        .then(res => {
          alert(res.data.msg);
          window.location.href = "/admin/user-list";
        })
        .catch(err =>{
          console.log('Error from ShowBookList');
        })
        
    }
}
const AdminUserCard = (props) => {
    const  reqq  = props.reqq;


    
    return(
        <>
                
                
                <tr>
                <td className="lang">{ reqq.name }</td>
                <td className="lang">{ reqq.email }</td>
                <td className="lang">{ reqq.createdAt }</td>
                <td><Link className="lang" to={`/view-user/${reqq._id}`} >View</Link> &nbsp; <span data-id={reqq._id}  onClick={deleteUser} className="lang lang1" to={`/delete-user/${reqq._id}`} >Delete</span></td>
                </tr>

                
                
                </>   
            
    )
};

export default AdminUserCard;